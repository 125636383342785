import * as React from 'react';
import accessTokenService from '../../../api/accessToken';
import useI18n from '../../../hooks/useI18n';
import CellType from '../../../constants/type';
import TableData from '../../custom/Table/TableData'
import { Box, Typography, Modal, Button } from '@mui/material';
import AddAccessToken from './AddAccessToken';
import useUserInfo from '../../../hooks/useUserInfo';
import Forbidden from '../Errors/Forbidden';

export default function AccessTokens() {
    const { language, l } = useI18n();
    const [accessTokens, setAccessTokens] = React.useState(null);
    const [confirmDeleteId, setConfirmDeleteId] = React.useState(null);
    const [error, setError] = React.useState(null);
    const {user} = useUserInfo();

    const columns = [
        { id: 'company_name', label: l('Company Name'), minWidth: 100, type: CellType.STRING },
        { id: 'email', label: l('Email'), minWidth: 60, type: CellType.STRING },
        { id: 'user_name', label: l('For User Name'), minWidth: 100, type: CellType.STRING },
        { id: 'comment', label: l('User Role'), minWidth: 100, type: CellType.STRING },
        { id: 'valid_until', label: l('Valid Until'), minWidth: 150, type: CellType.DATE },
        { id: 'created_at', label: l('Created At'), minWidth: 150, type: CellType.DATE }
    ];

    const openConfirmDeleteModal = (id) => {
        setConfirmDeleteId(id);
    };

    const closeConfirmDeleteModal = () => {
        setConfirmDeleteId(null);
    };
    
    const handleDeleteRow = async (id) => {
        try {
            openConfirmDeleteModal(id);
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            await accessTokenService.deleteAccessTokenAsync({ id: confirmDeleteId, lang: language });
            const updatedAccessTokens = accessTokens.filter(token => token.id !== confirmDeleteId);
            setAccessTokens(updatedAccessTokens);
            closeConfirmDeleteModal();
        } catch (error) {
            console.error('Error confirm deleting row:', error);
        }
    };

    const getAccessTokenList = async () => {
        try {
            const result = await accessTokenService.getAccessTokenListAsync(sessionStorage.getItem('company'), language);
            if (result) setAccessTokens(result?.sort((a, b) => a.id - b.id));
        } catch (error) {
            console.error('Error fetching access tokens:', error);
            setError(error.message);
        }
    };

    React.useEffect(() => {
        getAccessTokenList();
    }, []);

    return ( 
        <>
            <Box display="flex"
                 flexDirection='row'
                 alignItems="center"
                 justifyContent="space-between"
                 margin={'10px 0'}>
                <Typography component="h2" variant="h5">
                    {l('Access Tokens')}
                </Typography>
                    {
                        user?.isAllowCreateAccessTokens ?
                            error ? null : <AddAccessToken companyId={sessionStorage.getItem('company')} onSubmitSuccess={getAccessTokenList} />
                            :
                            ''
                    }
            </Box>
            {error ? <Forbidden error={error}/> 
            :
            <TableData columns={columns} onDeleteRow={handleDeleteRow} rows={accessTokens} showDeleteButton/>}
            
                {
                    user?.isAllowDeleteAccessTokens ?
                        <Modal open={Boolean(confirmDeleteId)} onClose={closeConfirmDeleteModal}>
                            <Box
                                component="form"
                                sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                                noValidate
                                autoComplete="off"
                                className="modal">

                                <Typography id="modal-modal-title" variant="h6" color='#1976d2' component="h2" sx={{ fontSize: 24 }}>
                                    {l('Are you sure you want to delete this access?')}
                                </Typography>
                                <Box sx={{ mt: 3 }}>
                                    <Button onClick={handleConfirmDelete} sx={{ mr: 3 }} type="button" size="small" variant="contained" color='success'>{l('Confirm')}</Button>
                                    <Button onClick={closeConfirmDeleteModal} size="small" variant="contained" color='error'>{'Cancel'}</Button>
                                </Box>
                            </Box>
                        </Modal>
                        :
                        ''
                }
        </>
    );
}
