import { getData, postData } from "../lib/fetchApi";
import { SMS_SETTING_URLS } from "../constants/urls";
import { defaultLocale } from "../config";

const smsSettingService = {
    getSMSSettingAsync: (lang = defaultLocale) => {
        return getData({ url: SMS_SETTING_URLS.LIST, lang })   
    },
    updateSMSSettingAsync: ({ data, lang = defaultLocale }) => {  
        return postData({ url: SMS_SETTING_URLS.UPDATE, lang, data })   
    }
}
export default smsSettingService;