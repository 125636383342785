import React from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

function DatePickerRange({defaultStartDate, defaultEndDate, lableStart, lableEnd, onChangeStartDate, onChangeEndDate, sx, type}) {
    const [start, setStart] = React.useState(defaultStartDate);
    const [end, setEnd] = React.useState(defaultEndDate);

    switch (type) {
        case 'TimePicker':
            return (
                <Box sx={sx}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <TimePicker ampm={false} onAccept={onChangeStartDate} defaultValue={dayjs(start)} sx={{ width: '50%' }} label={lableStart} />
                            <HorizontalRuleIcon color='disabled'/>
                            <TimePicker ampm={false} onAccept={onChangeEndDate}  defaultValue={dayjs(end)} sx={{ width: '50%' }} label={lableEnd} />
                        </Box>
                    </LocalizationProvider>
                </Box>
            );
        case 'DatePicker':
            return (
                <Box sx={sx}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <DatePicker format='DD.MM.YYYY' onChange={() => {}} onAccept={onChangeStartDate}   defaultValue={dayjs(start)} sx={{ width: '50%' }} label={lableStart} />
                            <HorizontalRuleIcon color='disabled'/>
                            <DatePicker format='DD.MM.YYYY' onAccept={onChangeEndDate}  defaultValue={dayjs(end)} sx={{ width: '50%' }} label={lableEnd} />
                        </Box>
                    </LocalizationProvider>
                </Box>
            );
        default:
            return (
                <Box sx={sx}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <DateTimePicker inputRef={(e) => {e?.setAttribute('disabled', 'true')}} key={'from'} ampm={false} format='DD.MM.YYYY HH:mm'  onAccept={onChangeStartDate} defaultValue={dayjs(start)} sx={{ width: '50%' }} label={lableStart} />
                            <HorizontalRuleIcon color='disabled'/>
                            <DateTimePicker inputRef={(e) => {e?.setAttribute('disabled', 'true')}} key={'to'} ampm={false} format='DD.MM.YYYY HH:mm'  onAccept={onChangeEndDate} defaultValue={dayjs(end)} sx={{ width: '50%' }} label={lableEnd} />
                        </Box>
                    </LocalizationProvider>
                </Box>
            );
    }

   
}

export default DatePickerRange;