import { getData, postData } from "../lib/fetchApi";
import { ROLE_URLS } from "../constants/urls";
import { defaultLocale } from "../config";

const roleService = {
    getRoleListAsync: (lang = defaultLocale) => {
        return getData({ url: ROLE_URLS.FIND_ALL, lang })
    },
    getAllowedRolesAsync: (lang = defaultLocale) => {
        return getData({ url: ROLE_URLS.FIND_ALLOWED_ROLES, lang })
    },
    createRoleAsync: (data, lang = defaultLocale) => {
        return postData({ url: ROLE_URLS.CREATE, lang, data })
    },
    updateRoleAsync: ({ role, data, lang = defaultLocale }) => {
        return postData({ url: ROLE_URLS.UPDATE + role, lang, data })
    },
    deleteRoleAsync: (data, lang = defaultLocale) => {
        return postData({ url: ROLE_URLS.DELETE + data, lang })
    },
    getFindByRoleAsync: (userRole, lang = defaultLocale) => {
        return getData({ url: ROLE_URLS.FIND_BY_ROLE + userRole, lang })
    }
}
export default roleService;