import { getData, postData } from "../lib/fetchApi";
import { ACCESS_TOKEN_URLS } from "../constants/urls";
import { defaultLocale } from "../config";

const accessTokenService = {
    getAccessTokenListAsync: (data, lang) => {
        return getData({ url: ACCESS_TOKEN_URLS.LIST + data, lang })   
    },
    createAccessTokenAsync: ({ data, lang = defaultLocale }) => {  
        return postData({ url: ACCESS_TOKEN_URLS.CREATE, lang, data })   
    },
    deleteAccessTokenAsync: ({ id, lang = defaultLocale }) => {  
        return postData({ url: ACCESS_TOKEN_URLS.DELETE + id, lang })   
    }
}
export default accessTokenService;