import * as React from 'react';
import useI18n from '../../../hooks/useI18n';
import { Box, Button } from '@mui/material';
import smsSettingService from '../../../api/smsSetting';
import { styled } from '@mui/material/styles';
import { 
    Container,
    Card,
    CardContent,
    FormHelperText,
    FormControl,
    OutlinedInput 
} from '@mui/material';

const AdaptiveBox = styled(Box)(({ theme }) => ({
    '@media (max-width: 720px)': {
        flexWrap: 'wrap'
    }
}));

export default function SMSSetting() {
    const { language, l } = useI18n();
    const [formData, setFormData] = React.useState({});
    const [successMessage, setSuccessMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        const getSMSSettingData = async () => {
            try {
                const result = await smsSettingService.getSMSSettingAsync(language);
                setFormData(result);
            } catch (error) {
                console.error('Error fetching company:', error);
            }
        };
        getSMSSettingData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const { balance, updated_at, ...validData } = formData;
            const updatedSMSSetting = await smsSettingService.updateSMSSettingAsync({ data: validData, lang: language });
            if (updatedSMSSetting) {
                setOpen(true);
                setSuccessMessage(l('SMSSetting saved'));
                setTimeout(() => {
                    setOpen(false); 
                }, 3000);
            }
        } catch (error) {
            console.error('Error updating smsSetting:', error);
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    return (
        <Container component="main">
            <Card variant="outlined">
                <CardContent sx={{ p: 4 }}>
                    <Box
                        component="form"
                        sx={{ '& .MuiTextField-root': { width: '100%' } }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <AdaptiveBox display="flex" gap={1}>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <FormHelperText id="api_path">{l("API Path")}</FormHelperText>
                                <OutlinedInput
                                    id="api_path"
                                    name="api_path"
                                    value={formData?.api_path || ''}
                                    aria-describedby="api_path"
                                    size="small"
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <FormHelperText id="api_login">{l("API Login")}</FormHelperText>
                                <OutlinedInput
                                    id="api_login"
                                    name="api_login"
                                    value={formData?.api_login || ''}
                                    aria-describedby="api_login"
                                    size="small"
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </AdaptiveBox>
                        <AdaptiveBox display="flex" gap={1}>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <FormHelperText id="api_password">{l("API Password")}</FormHelperText>
                                <OutlinedInput
                                    id="api_password"
                                    name="api_password"
                                    value={formData?.api_password || ''}
                                    aria-describedby="api_password"
                                    size="small"
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <FormHelperText id="sms_title">{l("SMS title")}</FormHelperText>
                                <OutlinedInput
                                    id="sms_title"
                                    name="sms_title"
                                    value={formData?.sms_title || ''}
                                    aria-describedby="sms_title"
                                    size="small"
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </AdaptiveBox>
                        <AdaptiveBox display="flex" gap={1}>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <FormHelperText id="balance">{l("Balance")}</FormHelperText>
                                <OutlinedInput
                                    id="balance"
                                    name="balance"
                                    value={formData?.balance || ''}
                                    aria-describedby="balance"
                                    size="small"
                                    disabled
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <FormHelperText id="updated_at">{l("Updated at")}</FormHelperText>
                                <OutlinedInput
                                    id="updated_at"
                                    name="updated_at"
                                    value={formData?.updated_at || ''}
                                    aria-describedby="updated_at"
                                    size="small"
                                    disabled
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </AdaptiveBox>
                        <Box display="flex" justifyContent="space-between">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                size='small'
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {l("Save changes")}
                            </Button>
                            {open && (
                                <Box sx={{ color: 'green', alignSelf: 'center', ml: 2 }}>
                                    {successMessage}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    );
}