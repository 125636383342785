import * as React from 'react';
import useI18n from '../../../hooks/useI18n';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReportIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';
import { TextField } from '@mui/material';

// available types of modal is 'confirm', 'info', 'danger', 'warning', 'success'

export default function ModalWithFild({
  isOpened = false, 
  colorOK = 'primary', 
  type, title, text, error, handleOk, handleChangeFilde, handleChangePassword, handleClose}) {

  const [open, setOpen] = React.useState(isOpened);
  const [showPassword, setShowPassword] = React.useState(false);
  const { l } = useI18n();
  let color = ""; 
  let icon = "";

  React.useEffect(() => {
    setOpen(isOpened);
  }, [isOpened])

  switch (type) {
    case "confirm": {
      color = "info.main";
      icon = <InfoIcon sx={{ position: "relative", top: 6}}></ InfoIcon>
      break;
    }
    case "warning": {
      color = "warning.main";
      icon = <WarningIcon sx={{ position: "relative", top: 6}}></ WarningIcon>
      break;
    }
    case "info": {
      color = "info.main";
      icon = <InfoIcon sx={{ position: "relative", top: 6}}></ InfoIcon>
      break;
    }
    case "danger": {
      color = "#c41c1c";
      icon = <ReportIcon sx={{ position: "relative", top: 6}}></ ReportIcon>
      break;
    }
    case "success": {
      color = "success.main";
      icon = <CheckCircleIcon sx={{ position: "relative", top: 6}}></ CheckCircleIcon>
      break;
    }
    default: {
      color = "info.main";
      icon = <InfoIcon sx={{ position: "relative", top: 6}}></ InfoIcon>
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal" display={'flex'} gap={1}>
          <Typography id="modal-modal-title" width={'100%'} display={'flex'} gap={1} align='left' variant="h6" color={color} component="h2" sx={{fontSize: 24}}>
            {icon}{title}
          </Typography>
            {text}
            {handleChangeFilde ? <TextField fullWidth size='small' onChange={handleChangeFilde}/> : ''}
            {handleChangePassword ?
            <TextField
            margin="normal"
            required
            error={error ? true : false}
            fullWidth
            onChange={handleChangePassword}
            name="password"
            label={l("Password")}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                  <InputAdornment position='end'>
                      <IconButton 
                      onClick={() => {setShowPassword(show => !show)}} 
                      onMouseDown={(e) => {e.preventDefault();}} 
                      edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                  </InputAdornment>
              )
            }}
            id="password"
            autoComplete="current-password"
          /> : ''}
            {error ? <Typography id="modal-modal-description" width={'100%'} color="red" variant="body1" component="p">{error + '!'}</Typography> : ''}
            {type !== 'confirm' ? 
            <Button type='submit' size="small" variant="contained" onClick={handleOk}>{l('Ok')}</Button>
             : 
            <Box display={'flex'} width={'100%'} gap={1}>
              <Button type='submit' color={colorOK} sx={{width: '100%'}} onClick={handleOk} size="small" variant="contained">{l('Ok')}</Button>
              <Button sx={{width: '100%'}} onClick={handleClose} size="small" variant="contained">{l('Cancel')}</Button>
            </Box>}
        </Box>
      </Modal>
    </>
  );
}