import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import deviceService from '../../../api/device';
import CellType from '../../../constants/type';
import useI18n from '../../../hooks/useI18n';
import TableData from '../../custom/Table/TableData';
import AddDevice from '../Devices/AddDevice';
import useUserInfo from '../../../hooks/useUserInfo';
import { currentCompanyState } from '../../../state/companies';
import { useRecoilState } from 'recoil';
import { ERRORS } from '../../../constants/errors';
import Forbidden from '../Errors/Forbidden';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`action-tabpanel-${index}`}
        aria-labelledby={`action-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{  }}>{children}</Box>}
      </Typography>
    );
  }

export default function Devices() {
    const { language, l } = useI18n();

    const columns = [
      { id: 'comment', label: l('System Name'), minWidth: 60, type: CellType.STRING },
      { id: 'status_flag', label: l('Status'), minWidth: 50, type: CellType.BOOLSTATUS },
      { id: 'status_updated_at', label: l('Time/Date data updating'), minWidth: 90, type: CellType.DATE },
      { id: 'status_on_off', label: l('Filtration'), minWidth: 100, type: CellType.BOOLEAN },
      { id: 'connected', label: l('Connection'), minWidth: 60, type: CellType.BOOLCONNECTED },
      //{ id: 'connection_updated_at', label: l('Connection Updated At'), minWidth: 90, type: CellType.DATE },
      { id: 'ip', label: l('IP'), minWidth: 150, type: CellType.STRING },
      { id: 'port', label: l('Port'), minWidth: 100, type: CellType.STRING },
      { id: 'is_active', label: l('Status of Data Collection'), minWidth: 100, type: CellType.BOOLEAN }
    ];

    const [updateDevices, setUpdateDevices] = React.useState(false);
    const [devices, setDevices] = React.useState(null);
    const [selectedCompany, setSelectedCompany] = useRecoilState(currentCompanyState);
    const [error, setError] = React.useState('');
    const navigate = useNavigate();
    const {user} = useUserInfo();

    React.useEffect(() => {
        let isResetData = false
        const getDataDevices = async () => {
            try {
                const result = await deviceService.listByCompanyAsync(sessionStorage.getItem('company'), language);
                setDevices(result?.sort((a, b) => a.id - b.id));
                isResetData = true;
            } catch (error) {
                console.error('Error fetching devices:', error);
                isResetData = false;
                if (error.message === ERRORS.FORBIDDEN) {
                  setError(error.message)
                }
            }
        };
        getDataDevices();

        if(isResetData){
          const interval = setInterval(getDataDevices, 10000);
          // Clear interval on component unmount
          return () => clearInterval(interval);
        }
        
    }, [updateDevices, selectedCompany?.id]);

    function setClickRow(params) {
        navigate(`/device/${params.id}`);
    }

    return (
        <>
          <Box display="flex"
               alignItems="center"
               justifyContent="space-between"
               margin={'10px 0'}>
            <Typography component="h2" variant="h5">
                {l('Devices')}
            </Typography>
            {user?.isAllowCreateDevices ? 
              <AddDevice updateDevices={() => setUpdateDevices(!updateDevices)} />
            : 
            ''}
          </Box>
          {error ? 
            <Forbidden error={error}/>
          : 
          <TableData isPointCursor={true} setClickRow={setClickRow} columns={columns} rows={devices} />}
        </>
    );
}
