import { getData, postData } from "../lib/fetchApi";
import { USERS_URLS } from "../constants/urls";
import { defaultLocale } from "../config";

const userService = {
    findById: (id, lang = defaultLocale) => {
        return getData({ url: USERS_URLS.FIND_BY_ID + id, lang })
    },
    getUserListAsync: (lang = defaultLocale) => {
        return getData({ url: USERS_URLS.FIND_ALL, lang })
    },
    findAllForCompanyAsync: (data, lang) => {
        return getData({ url: USERS_URLS.FIND_ALL_FOR_COMPANY + data, lang })
    },
    createUserAsync: (data, lang) => {
        return postData({ url: USERS_URLS.CREATE, lang, data })
    },
    updateUserAsync: ({ id, data, lang }) => {
        return postData({ url: USERS_URLS.UPDATE + id, lang, data })
    },
    updateAlarmTimeStampAsync: (lang = defaultLocale) => {
        return postData({ url: USERS_URLS.UPDATE_ALARM_TIMESTAMP, lang })
    },
    deleteUserAsync: (data, lang) => {
        return postData({ url: USERS_URLS.DELETE + data, lang })
    },
    changePasswordAsync: (data, lang) => {
        return postData({ url: USERS_URLS.CHANGE_PASSWORD, lang, data })
    }
}
export default userService;
