import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import CircleIcon from '@mui/icons-material/Circle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import CellType from '../../../constants/type';
import { dataFormat } from '../../../config';
import useI18n from '../../../hooks/useI18n';
import { Box } from '@mui/material';

function setCell(params) {
    const {type, data} = params;

    switch (type) {
        case CellType.STRING:
            return data;
        case CellType.NUMBER:
            return data;
        case CellType.DATE:
            return moment(data).format(dataFormat);
        case CellType.BOOLEAN:
            if(data)
                return <CheckCircleIcon color='success' /> 
            else
                return <CheckCircleIcon color='disabled' />;
        case CellType.BOOLCONNECTED:
            if(data)
                return <WifiIcon color='success' />
            else
                return <WifiOffIcon color='disabled' />;
        case CellType.BOOLSTATUS:
            if (!data) return <CircleIcon color='error' />
            return <CircleIcon color={data} />
        case CellType.BOOLALARM:
            switch (data) {
                case 0:
                    return <ErrorIcon color='error' />
                case 1:
                    return <ErrorIcon color='warning' />
                case 2:
                    return <ErrorIcon color='success' />
                default:
                    return data;
            }
        default:
            return data;
    }

}

export default function TableData(prop) {
    let {columns, rows , setClickRow, isPointCursor, showDeleteButton, onDeleteRow} = prop;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const { l } = useI18n();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDeleteClick = async (row) => {
        try {
            await onDeleteRow(row.id);
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    };
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ height: '100%' }}>
                <Table sx={{width: '100%'}} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns ? columns?.map((column) => (
                                <TableCell
                                    sx={{padding: 1}}
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                                >
                                    {column.label}
                                </TableCell>
                            )) :
                                <TableCell>
                                    <Skeleton variant="rounded" width={60} height={60} />
                                </TableCell>
                            }
                            {showDeleteButton && (
                                <TableCell
                                    style={{ fontWeight: 'bold' }}
                                >
                                    {l('Actions')}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows ? rows
                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            ?.map((row, i) => {
                                return (
                                    <TableRow
                                        hover={isPointCursor}
                                        sx={{ cursor: isPointCursor ? 'pointer' : 'default' }}
                                        onClick={() => {
                                            if (setClickRow) {
                                                setClickRow(row);
                                            }
                                        }
                                        }
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id || i}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell sx={{ padding: 1 }} key={`${row.id}-${column.id}`}>
                                                    <Box justifyContent={column?.align} sx={{ display: 'flex' }} alignItems={'center'}>
                                                        {setCell({ type: column.type, data: value })}
                                                    </Box>
                                                </TableCell>
                                            );
                                        })}
                                        {showDeleteButton && ( 
                                            <TableCell sx={{ padding: 0 }}>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => handleDeleteClick(row)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            }) :
                            Array(3).fill(0).map((_, i) => (<TableRow key={i}>
                                {
                                    columns.map((_, j) =>
                                    (<TableCell key={`${i}-${j}`} sx={{ margin: '10px 0px' }}>
                                        <Skeleton variant="rounded" width={"100%"} height={20} />
                                    </TableCell>))
                                }
                            </TableRow>))

                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={rows ? rows?.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={`${l('Rows per page')}:`}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${l('of')} ${count}`}
            />
        </Paper>
    );
}