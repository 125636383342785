import { Button, Checkbox, FormControlLabel, FormHelperText, OutlinedInput, Divider, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import deviceService from '../../../api/device';
import useI18n from '../../../hooks/useI18n';
import useUserInfo from '../../../hooks/useUserInfo';
import BasicModal from '../../custom/Modal/BasicModal';
import ModalWithFild from '../../custom/Modal/ModalWithFild';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.8)', // Змінюємо колір фону на білий при наведенні
    },
  }));

export default function Setting({device}) {
    const { language, l } = useI18n();
    const { id } = useParams();
    const [title, setTitle] = React.useState('');
    const [text, setText] = React.useState('');
    const [image, setImage] = React.useState('');
    const [data, setData] = React.useState(device);
    const [file, setFile] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [deleteError, setDeleteError] = React.useState('');
    const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [deviseSetting, setDeviseSetting] = React.useState({id});
    const navigate = useNavigate();
    const [type, setType] = React.useState('');
    const {user} = useUserInfo();

    const getImage = async () => {
        const blob = await deviceService.getImageByDeviceId(id);
        const imageUrl = URL.createObjectURL(blob);
        setImage(imageUrl || '');
    };

    const getAlarmSettings = async () => {
        const result = await deviceService.getAlarmSettings(id, language);
        setDeviseSetting(result);
    };

    const postAlarmSettings = async (data) => {
       await deviceService.postAlarmSettings(data, language);
    };
   
    React.useEffect(() => {
        try {
            getImage();
        } catch (e) {
            console.error('Error  getImageByDeviceId:>> ', e);
        }

        try {
            getAlarmSettings();
        } catch (e) {
            console.error('Error  getAlarmSettings:>> ', e);
        }
    }, []);

    function handleInputChange(event) {
        let { name, value, checked, files } = event.target;

        if (name === 'is_active') {
            setData({
              ...data,
              [name]: checked
            })

            return;
          }

        if ((name === 'image' && value) || event.dataTransfer) {
            handleDragEmpty(event);

            const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Масив дозволених MIME-типів

            if (event.dataTransfer) {
                files = event.dataTransfer.files;
                name = "image";
            }

            if (allowedTypes.includes(files[0].type)) {
                setFile(files[0]);
                const reader = new FileReader();
                reader.onload = () => {
                    setImage(reader.result);
                };
                reader.readAsDataURL(files[0]);
                return;
            } else {
                setOpen(open => !open);
                setType('warning');
                setTitle(l(''));
                setText(l('Дозволені тільки файли з розширеннями .jpeg, .jpg, .png'))
            }

           return;
        } 

        setData({
            ...data,
            [name]: value
        })
    }

    const deleteDevice = async () => {
        try {
            const result = await deviceService.deleteDeviceAsync({ id, data: { userId: user.id , confirmPassword }, language });
            if (result) {
                navigate('/devices');  
            }
        } catch (error) {
            console.error('Error delete user:', error);
            setDeleteError(error.message);
        }
    }

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
        setImage(fileReader.result)
    }

    const handleDragEmpty = (event) => {
        event.preventDefault();
        event.stopPropagation();
      }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const canDeleteImage = !image && !file;

            const updateDevice = await deviceService.updateDeviceAsync({ id: data.id, data: {...data, canDeleteImage }, lang: language, file, type: 'form-data' });

            if (updateDevice) {
                setTitle(l('Device saved'));
                setText(l('Saving was successfull. Device saved.'));
                setOpen(true);
                setType('success')
            }
        } catch (error) {
            console.error('Error update device:', error);
        }
    }

    const handleOnChange = (event) => {
        let { name, checked } = event.target;

        setDeviseSetting({
            ...deviseSetting,
            [name]: checked
          });
        
        postAlarmSettings({
            ...deviseSetting,
            [name]: checked
          });
      }

    return (
        <Box
            component="form"
            sx={{ '& .MuiTextField-root': { m: 1, width: '100%', padding: 3 }, display: 'flex', flexDirection: 'column', gap: 1, marginTop: 1 }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit} >
            <BasicModal isOpened={open}
                type={type}
                title={title}
                text={text}
                handle={() => setOpen(open => !open)} />

            <ModalWithFild isOpened={openConfirmModal}
                type={'confirm'}
                title={l('Confirm delete device')}
                error={deleteError}
                colorOK='error'
                text={<>
                <Typography id="modal-modal-description" sx={{ mt: 1, width: '100%' }}>
                    {l('Device') + ': '}<b>{data?.comment + '.'}</b>
                </Typography>
                <Typography sx={{ width: '100%' }}>{l('Enter the password to confirm the deletion') + '.'}</Typography></>}
                handleOk={deleteDevice}
                handleClose={() => setOpenConfirmModal(open => !open)}
                handle={() => setOpenConfirmModal(open => !open)}
                handleChangePassword={(e) => { setConfirmPassword(e.target.value) }}
            />
          
            <Box display={'flex'} flexWrap={'wrap'} flexDirection={'column'} sx={{ gap: 1 }} variant="outlined">
                <Box flexWrap={'wrap'} sx={{ display: 'flex', gap: 1, flexDirection: 'row' }}>
                    <Box>
                        <FormHelperText id="comment-helper-text">{l("Comment")}</FormHelperText>
                        <OutlinedInput
                            id="comment"
                            name="comment"
                            placeholder='Comment'
                            defaultValue={data?.comment}
                            aria-describedby="comment"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </Box>
                    <Box>
                        <FormHelperText id="port-helper-text">{l("Port")}</FormHelperText>
                        <OutlinedInput
                            id="port"
                            name="port"
                            placeholder='Port'
                            defaultValue={data?.port}
                            aria-describedby="port"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </Box>
                    <Box>
                        <FormHelperText id="cip_level_PFC-helper-text">{l("cip_level_PFC")}</FormHelperText>
                        <OutlinedInput
                            id="cip_level_PFC"
                            name="cip_level_PFC"
                            placeholder='Cip_level_PFC'
                            defaultValue={data?.cip_level_PFC}
                            aria-describedby="cip_level_PFC"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </Box>
                    <Box>
                        <FormHelperText id="cip_level_PD-helper-text">{l("cip_level_PD")}</FormHelperText>
                        <OutlinedInput
                            id="cip_level_PD"
                            name="cip_level_PD"
                            placeholder='Cip_level_PD'
                            defaultValue={data?.cip_level_PD}
                            aria-describedby="cip_level_PD"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </Box>
                    <Box>
                        <FormHelperText id="permiate_press-helper-text">{l("permiate_press")}</FormHelperText>
                        <OutlinedInput
                            id="permiate_press"
                            name="permiate_press"
                            placeholder='Permiate_press'
                            defaultValue={data?.permiate_press}
                            aria-describedby="permiate_press"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </Box>
                    <Box>
                        <FormHelperText id="connection_timer-helper-text">{l("connection_timer")}</FormHelperText>
                        <OutlinedInput
                            id="connectionTimer"
                            name="connectionTimer"
                            defaultValue={data?.connection_timer}
                            aria-describedby="connectionTimer"
                            size="small"
                            onChange={handleInputChange}
                        />
                    </Box>
                    
                    <FormControlLabel sx={{marginTop: 3}} control={
                        data ? 
                        <Checkbox id="is_active"
                        checked={data?.is_active}
                        name="is_active"
                        onChange={handleInputChange}
                        size="small" />
                         : ''} 
                        label={l("Device is active")} /> 
                       
                    {
                        user?.isAllowEditDevices ?
                            <Button type='submit'
                                onClick={handleSubmit}
                                color="success"
                                variant="contained"
                                size='small'
                                sx={{ mt: 3 }}
                            >{l('Save changes')}</Button>
                            :
                            ""
                    }
                    {
                        user?.isAllowDeleteDevices ?
                            <Button
                                color="error"
                                variant="contained"
                                size='small'
                                sx={{ mt: 3 }}
                                onClick={() => setOpenConfirmModal(open => !open)}
                            >
                                {l('Delete device')}
                            </Button>
                            :
                            ""
                    }
                </Box>
                <Divider orientation="horizontal"/>
                <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                <FormGroup>
                    <FormControlLabel checked={deviseSetting?.allow_alarms_level0 || false} onChange={handleOnChange} name='allow_alarms_level0' control={<Switch />} label={l("Allow alarms level") + " " + `"${l('Errors')}"`} />
                    <FormControlLabel checked={deviseSetting?.allow_alarms_level1 || false} onChange={handleOnChange} name='allow_alarms_level1' control={<Switch />} label={l("Allow alarms level") + " " + `"${l('Warning')}"`} />
                    <FormControlLabel checked={deviseSetting?.allow_alarms_level2 || false} onChange={handleOnChange} name='allow_alarms_level2' control={<Switch />} label={l("Allow alarms level") + " " + `"${l('Messages')}"`} />
                </FormGroup>
                <Divider orientation="vertical" variant="middle" flexItem />
                <FormGroup>
                    <FormControlLabel checked={deviseSetting?.report_send_daily || false} onChange={handleOnChange} name='report_send_daily' control={<Switch />} label={l("Dayly report")} />
                    <FormControlLabel checked={deviseSetting?.report_send_monthly || false} onChange={handleOnChange} name='report_send_monthly' control={<Switch />} label={l('Monthly report')} />
                    <FormControlLabel checked={deviseSetting?.report_send_weekly || false} onChange={handleOnChange} name='report_send_weekly' control={<Switch />} label={l("Weekly report")} />
                </FormGroup>
            </Box>
            </Box>
        </Box>
    );
};