import moment from 'moment';

export default function CustomizedAxisTick({ x, y, payload, isShowHoursDate }) {
  const date = moment(payload.value).format('DD/MM/YYYY');
  const dateHours = moment(payload.value).format('HH:mm');
  
  //const firstDate = moment(payload[0].payload.date, 'HH:mm DD.MM.YYYY');
  /*const lastDate = moment(payload[payload.length - 1].payload.date, 'HH:mm DD.MM.YYYY');
  const diffInDays = lastDate.diff(firstDate, 'days');

  if (diffInDays <= 2) {
    date = moment(payload.value).format('HH:mm');
  }*/

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={-10}
        dy={16}
        textAnchor="end"
        fill="#466b62"
        transform="rotate(-45)"
        fontSize='12'
      >
        {isShowHoursDate() ? dateHours : date}
      </text>
    </g>
  );
}