import React from 'react';
import { IMaskInput } from 'react-imask';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import useI18n from '../../../hooks/useI18n';

const CodeMask = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+{38}(000)000-00-00"
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });

const PhoneInput = ({sx, handleChange, error, isLableOnBorder = false, name, value, heightInput}) => {
    const { l } = useI18n();
    return (
        <Box sx={sx}>
        {!isLableOnBorder ? <InputLabel htmlFor="phone-input">{l("Phone number")}</InputLabel> 
        : 
        <InputLabel sx={{
            top: 10, 
            left: 10, 
            zIndex: 1, 
            backgroundColor: 'white', 
            width: 'fit-content', 
            fontSize: 12, 
            border: '5px solid white',
            borderBottomWidth: 0,
            borderTopWidth: 0,
            }} error={error} htmlFor="phone-input">{l("Phone number")}</InputLabel> }
            
            <OutlinedInput
                id="phone-input"
                sx={{
                    '& input': {
                        padding: 1,
                    },
                    width: '100%',
                    height: heightInput
                }}
                placeholder="+38(000)000-00-00"
                value={value}
                onChange={handleChange}
                name={name}
                inputComponent={CodeMask}
                autoComplete='off'
                autoSave='off'
                required
                error={error}
            />
        </Box>

    );
};

export default PhoneInput;
