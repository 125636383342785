import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

const PDFViewer = ({ pdfBlob }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '500px', width: '100%' }}>
            <Document file={URL.createObjectURL(pdfBlob)}>
                <Page renderTextLayer={false}  renderAnnotationLayer={false} pageNumber={1} />
            </Document>
        </div>
    );
};

export default PDFViewer;