import * as React from 'react';
import companyService from '../../../api/company';
import useI18n from '../../../hooks/useI18n';
import { useNavigate } from 'react-router-dom';
import CellType from '../../../constants/type';
import TableData from '../../custom/Table/TableData'
import { Box, Typography } from '@mui/material';
import AddCompany from './AddCompany';
import useUserInfo from '../../../hooks/useUserInfo';

export default function Companies() {
    const { language, l } = useI18n();
    const {user} = useUserInfo();

    const columns = [
        { id: 'company_name', label: l('Company Name'), minWidth: 200, type: CellType.STRING },
        { id: 'company_description', label: l('Description'), minWidth: 200, type: CellType.STRING },
        { id: 'created_at', label: l('Created At'), minWidth: 200, type: CellType.DATE },
        { id: 'timezone', label: l('Timezone'), minWidth: 50, type: CellType.NUMBER },
        { id: 'locale', label: l('Locale'), minWidth: 50, type: CellType.STRING },
    ];

    const navigate = useNavigate();
    const [companies, setCompanies] = React.useState(null);

    const getDataCompanies = async () => {
        try {
            const result = await companyService.getCompanyListAsync(language);
            if(result)
            setCompanies(result?.sort((a, b) => a.id - b.id));
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };
    React.useEffect(() => {
        getDataCompanies();
    }, []);

    function setClickRow(params) {
        navigate(`/company/${params.id}`);
    }

    if (!companies?.length) {
        return <div>{l('Loading companies...')}</div>;
    }

// available types of modal is 'confirm', 'info', 'danger', 'warning', 'success'

    return (
        <>
            <Box display="flex"
                 flexDirection='row'
                 alignItems="center"
                 justifyContent="space-between"
                 margin={'10px 0'}>
                <Typography component="h2" variant="h5">
                    {l('Companies')}
                </Typography>
                {user?.isAllowCreateCompanies ?
                    <AddCompany onSubmitSuccess={getDataCompanies} />
                    :
                    ""
                }
            </Box>
            <TableData isPointCursor={true} columns={columns} setClickRow={setClickRow} rows={companies} />
        </>
    );
}
