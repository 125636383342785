import * as React from 'react';
import Typography from '@mui/material/Typography';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import Box from '@mui/material/Box';
import useI18n from '../../../hooks/useI18n';

function Forbidden({error}) {
    const { l } = useI18n();

    return (<>
        <Box 
        height={"60%"} 
        display={'flex'} 
        flexDirection={'column'} 
        justifyContent={'center'} 
        alignItems={'center'}>
            <Typography variant={'h4'} align='center'>{l(error) + '!'}</Typography>
            <LockPersonIcon sx={{ fontSize: 200 }} color='disabled' />
        </Box>

    </>);
}

export default Forbidden;