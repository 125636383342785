import {
  Alert,
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  Typography,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import deviceService from '../../../api/device';
import probeService from '../../../api/probe';
import useI18n from '../../../hooks/useI18n';
import DatePickerRange from '../../custom/DatePickerRange/DatePickerRange';
import CustomTooltip from '../../custom/Graphics/CustomTooltip';
import CustomizedAxisTick from '../../custom/Graphics/CustomizedAxisTick';
import ExportGraphic from '../Graphics/ExportGraphic';
import CheckboxesBox from './Checkboxes';
import Close from '@mui/icons-material/Close';

const colors = [
	"#c2130a",
	"#0dc20a",
	"#0a8ec2",
	"#190ac2",
	"#c2bc0a",
	"#c20a8e",
	"#665051",
	"#89c756",
	"#a156c7",
	"#fffb05",
];

const WhiteBrushBorders = ({ x, y, width, height, startIndex, endIndex }) => {
  const centerX = x(startIndex) + (x(endIndex) - x(startIndex)) / 2;
  const centerWidth = 20; // Change this to adjust the width of the central filled area
  return (
    <g>
      {/* Background rectangle */}
      <Rectangle
        x={x(startIndex)}
        y={y}
        width={x(endIndex) - x(startIndex)}
        height={height}
        fill="white"
        fillOpacity={0.5}
        stroke="white"
        strokeWidth={2}
      />
      {/* Left side */}
      <Rectangle
        x={x(startIndex)}
        y={y}
        width={centerX - x(startIndex) - centerWidth / 2}
        height={height}
        fill="white"
      />
      {/* Center filled rectangle */}
      <Rectangle
        x={centerX - centerWidth / 2}
        y={y}
        width={centerWidth}
        height={height}
        fill="#1976d2" // Change this to your desired color
      />
      {/* Right side */}
      <Rectangle
        x={centerX + centerWidth / 2}
        y={y}
        width={x(endIndex) - centerX - centerWidth / 2}
        height={height}
        fill="white"
      />
    </g>
  );
};

function findMissingId(objects, ids) {
	// Создаем множество (Set) из идентификаторов объектов
	const idSet = new Set(objects.map(obj => obj.id));
  
	// Перебираем массив идентификаторов и находим отсутствующий идентификатор
	for (let id of ids) {
	  if (!idSet.has(id)) {
		return id; // Возвращаем отсутствующий идентификатор
	  }
	}
  
	return null; // Если все идентификаторы присутствуют, возвращаем null
  }

const getActiveIds = (objectIds = {}) => {
	let activeIdsArray = [];
	for (let id in objectIds) {
		if (objectIds[id]) activeIdsArray.push(id);
	}
	return activeIdsArray;
}

const defaultFrom = moment().subtract(7, 'days').toISOString();
const defaultTo = moment().toISOString();

//////////////////////////////////----Graphics----////////////////////////////////////////////////

export default function Graphics({probeId}) {
  const interval = 9000;
	const { language, l } = useI18n();
	const { deviceId } = useParams();

	const [probe, setProbe] = useState();
  const [device, setDevice] = useState();
	const [probes, setProbes] = useState([]);
	const [probesInfo, setProbesInfo] = useState([]);
	const [dots, setDots] = useState([]);

	const [from, setStartDate] = useState(defaultFrom);
	const [to, setEndDate] = useState(defaultTo);
	
	const prevFrom = useRef(from);
  const prevTo = useRef(to);

	const [toCompare, setToCompare] = useState([]);	
	const [compareIds, setToCompareIds] = useState({ [probeId]: true });

  const [indexDifference, setIndexDifference] = useState(1);

  const [opentInfoAlert, setOpenInfoAlert] = useState(false);

	const getProbesInfoWithoutValues = async () => {
		try {
			const result = await probeService.getFastProbeListByDeviceId({ id: deviceId, lang: language });
			return result?.sort((a, b) => a.id - b.id);
		} catch (error) {
			console.error('Error fetching probes:', error);
		}
	};
  
	useEffect(() => {
		// get All probes informatio for checkboxes and set current probe without datavalues
		getProbesInfoWithoutValues().then((probesInfo = []) => {
			setProbesInfo(probesInfo);
			probesInfo.forEach(probeInfo => {
        probeInfo.comment = l(probeInfo.comment);
				if (probeInfo.id === probeId) {
          setProbe(probeInfo);
          setToCompareIds({[probeId]: true});
        };
			});
		}).catch((error) => {
			console.error('getProbesName error:>> ', error);
		});

    const getDevice = async () => {
      try {
        const resalt = await deviceService.getFindDeviceById(deviceId, language);
        setDevice(resalt);
      } catch (error) {
        console.error('Error fetching device:', error);
      }
      
    }

    getDevice();

	}, [probeId, language]);

  const handleCheckboxChange = (event) => {
    setToCompare({
      ...toCompare,
      [event.target.name]: event.target.checked,
    });

    setToCompareIds({
      ...compareIds,
      [event.target.id]: event.target.checked
    })
  };

  const getAndSetChartData = async (isChangeDates) => {
    try {
      const activeIds = getActiveIds(compareIds);
      let probeArray = [];

      if (isChangeDates) {
        const activeProbsByDate = await probeService.getReportByProbesIdArrayAsync({ ids: activeIds, from, to, lang: language });

        setProbes(activeProbsByDate);
        probeArray = activeProbsByDate;
      } else {

        const newId = findMissingId(probes, activeIds);
        const newProbe = await probeService.getReportByProbeIdAsync({ id: newId || probeId, from, to, lang: language });
        if (!probes.find(prb => prb.id === newProbe.id))
          probes.push(newProbe);

        setProbes(probes);
        probeArray = probes;
      }
      // вот этот кусок кода желательно перенести на сервер
      // start
      const linesInfo = {};
      for (let prb of probeArray) {
        linesInfo[prb.id] = {
          'values': Array.from(prb.dataTypes),
          'unit_text': l(prb.unit_text),
          'comment': l(prb.comment),
          'minvalue': prb.minvalue,
          'maxvalue': prb.maxvalue
        }
      }
      const generatedDots = [];
      const activeId = activeIds[0];
      for (let i = 0; i < linesInfo[activeId]?.values.length; i++) {
        let obj = {};
        obj.date = linesInfo[activeId]?.values[i][0];
        // obj.day = moment(linesInfo[activeId].values[i][1], 'HH:mm DD.MM.YYYY').format('DD.MM.YYYY');
        // obj.hour = moment(linesInfo[activeId].values[i][1], 'HH:mm DD.MM.YYYY').format('HH');
        for (let line in linesInfo) {

          if (!linesInfo[line]?.values[i]?.length) break;

          let comment = linesInfo[line]?.values[i]?.[1];
          let value = linesInfo[line]?.values[i][1];

          if (comment === true) comment = 1;
          if (comment === false) comment = 0;

          if (value === true) value = 1;
          if (value === false) value = 0;

          if (!isNaN(parseFloat(comment))) obj[linesInfo[line]?.comment] = parseFloat(value);
        }
        generatedDots.push(obj);
      }
      setDots(generatedDots);

      setIndexDifference(generatedDots.length / interval);
      // end
    } catch (error) {
      console.error(error)
    }
  }

  	useEffect(() => {
		const isChangeDates = prevFrom.current !== from || prevTo.current !== to;

		if (isChangeDates) {
			prevFrom.current = from;
			prevTo.current = to;
		}
		getAndSetChartData(isChangeDates);
    isInfoDaysRange();
	}, [compareIds, from, to, language, probeId]);

  const handleStartDateChange = (date) => {
    const startDate = JSON.stringify(date); 
    setStartDate(startDate.replace(/"/g, ''));
  };

  const handleEndDateChange = (date) => {
    const endDate = JSON.stringify(date);
    setEndDate(endDate.replace(/"/g, ''));
  };

  const xAxises = () => {
    return (
      <XAxis dataKey="date" 
              height={60}
              interval={Math.round(24*60*indexDifference)}
              tick={<CustomizedAxisTick isShowHoursDate={isShowHoursDateFormateForXAxis} />} 
               />

    )
  }

  const isInfoDaysRange = () => {
    const firstDate = moment(from);
    const lastDate = moment(to);

    const dateDifference = lastDate.diff(firstDate);

    let daysDiff = moment.duration(dateDifference).asDays();

    if(daysDiff > 14){
      setOpenInfoAlert(true)
      return;
    }

    setOpenInfoAlert(false)
    return;
  }

  const isShowHoursDateFormateForXAxis = () => {
  const firstDate = moment(dots[0]?.date);
  const lastDate = moment(dots?.at(-1)?.date);

  const dateDifference = lastDate.diff(firstDate);

    let daysDiff = moment.duration(dateDifference).asDays();

    // Якщо різниця менше 8 днів, то буде дана можливість виводити час в форматі HH:mm
    if (daysDiff < 8) {
      daysDiff = daysDiff * indexDifference;
    }

    if(daysDiff < 1){
      return true;
    }

    return false;
  }

  const yAxises = () => {
    const newProbes = probes?.reduce((acc, probeInfo) => {
      if (((probeInfo.is_coil === null)
        && (toCompare[l(probeInfo.comment)]))
        || (probe?.comment === l(probeInfo.comment))) {

        if (!acc.find(acc => acc.comment === l(probeInfo.comment))) {
          acc.push(probeInfo);
        }

      }

      return acc
    }, []);

    return (
      <>
        { newProbes.map((probe) => (
              <YAxis label={{ 
                        value: `${l(probe.unit_text)}`,
                        style: { valueAnchor: 'middle' },
                        angle: 0,
                        position: 'top',
                        }}
                        yAxisId={`${l(probe.unit_text)}`}
                        domain={[probe.minvalue/2, 'auto']}
                        tick={{fontSize: 12}}
                        orientation="left"
                      	key={probe.id}          
              />
            )
          )
        }
      </>
    )
  }

  const yAxisesWithReferenceLine = () => {
    const y = yAxises();
    let result = <>{y}</>;
    
    if(y.props.children.length !== 0){
      result = (<>
        {y}
        {probe?.calc_marker === 'NPD' || probe?.calc_marker === 'NPF' && device
          ? <ReferenceLine  label="CIP" stroke="green" strokeWidth={2} yAxisId={`${l(probe?.unit_text)}`} y={device.cip_level_PFC}/> : ''}
      </>);
    }
  
    return result;
  }


  const lines = () => {
    const newProbes = probes?.reduce( (acc, probeInfo) => {
      if (toCompare[l(probeInfo.comment)] || probe?.comment === l(probeInfo.comment)) {
        if (!acc.find(acc => acc.comment === l(probeInfo.comment))) {
          acc.push(probeInfo)
        }
      }
      return acc
    }, [])
    return (
      <>
        { newProbes.map((probe, index) => (
          <Line key={probe.id}    
                yAxisId={l(probe.unit_text)}
                type="monotone"
                stroke={colors[index]}
                strokeWidth={2}
                dataKey={l(probe.comment)}
                dot={false}></Line>
            )
          )
        }
      </>
    )
  }

  return (
    <div style={{width: '100%'}}>
    <Box sx={{height: '60vh'}}>
        <Box padding={'10px 0px 0 0'}  display={'flex'} justifyContent={'end'}>
          <Typography component="h2" variant="h5">
          {l('Device')}: {device?.comment || ''}
          </Typography>
        </Box>
    <ResponsiveContainer width="98%" height='100%' className="padding-left">
      <LineChart
        data={dots}
        margin={{
          top: 20,
          right: 0,
          left: 10,
          bottom: 0
        }}
      >
        {/* stroke - цвет линии, 
            fill - цвет внутри точки, 
            activeDot - радиус активной точки, 
            strokeWidth - ширина линии
            strokeDasharray - настройка пунктирности линии
            interval - интерал отметок по оси Х в минутах
            dot - отрисовка точек
            Полный гайд по кастомизации https://recharts.org/en-US/guide/customize*/}
        {/* Серые пунктирные линии сетки */}
        <CartesianGrid strokeDasharray="3 3" />
        {/* Абсцисса */}
        {xAxises()}
         
        {/* Ордината */}
        {probes ? yAxisesWithReferenceLine() : ''}
        
        <Tooltip wrapperStyle={{ width: 150, backgroundColor: '#f0f3f5' }}
                 content={<CustomTooltip />}
        />

        <Legend verticalAlign="top" align="right" layout='vertical' />
        
        {lines()}

        {/* Ползунок */}
        <Brush 
        tickFormatter={(e) => moment(e).format('HH:mm DD.MM.YYYY')}
        onChange={(e) => {
            setIndexDifference(Math.abs(e.endIndex - e.startIndex) / interval);
          }}
        dataKey="date"
        height={30}
        stroke="#8884d8"/>
          <WhiteBrushBorders />
      </LineChart>
    </ResponsiveContainer>
    </Box>
    <Box display="flex"
        alignItems="flex-start"
        flexDirection='column'
        mt={8}
        justifyContent="left">
        <Box marginBottom={1.5} display="flex" alignItems="center" gap={3}>
            <DatePickerRange
                lableStart={l('Start time')}
                lableEnd={l('End time')}
                onChangeStartDate={handleStartDateChange}
                onChangeEndDate={handleEndDateChange}
                defaultStartDate={defaultFrom}
                defaultEndDate={defaultTo}
                sx={{ maxWidth: '500px' }}
            />
            <ExportGraphic probeId={probeId} from={from} to={to}/>
        </Box>
        <Collapse sx={{width: '100%', mb: 1.5}} in={opentInfoAlert}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenInfoAlert(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          severity="info"
        >
          {l('Data for a period of more than 14 days is displayed as an average per day')}!
        </Alert>
      </Collapse>
        <Box>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">{l('Select probes to compare')}</FormLabel>
                <FormGroup>
                    <CheckboxesBox probes={probesInfo} probe={probe} handleCheckboxChange={handleCheckboxChange} toCompare={toCompare}/>
                </FormGroup>
            </FormControl>
        </Box>
    </Box>
    </div>
  );
}
