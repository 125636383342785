import * as React from 'react';

import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import useI18n from '../../../hooks/useI18n'
import { styled } from '@mui/material/styles';
import companyService from '../../../api/company';
import { useRecoilState } from 'recoil';
import { currentCompanyState } from '../../../state/companies';
import Box from '@mui/material/Box';

const CustomSelect = styled(Select)({
    '&.MuiOutlinedInput-root': {
      border: 'none', // Видаляємо бордер
      '& fieldset': {
        border: 'none', // Видаляємо бордер
      },
      '&:hover fieldset': {
        border: 'none', // Видаляємо бордер при наведенні
      },
      '&.Mui-focused fieldset': {
        border: 'none', // Видаляємо бордер при фокусі
      },
      '& .MuiSvgIcon-root': {
        color: 'white', // Змінюємо колір іконки
      }
    },
    '& .MuiBox-root > img': { 
        marginRight: '10px',
        height: '50px',
    },
    '& .MuiSelect-select': {
        paddingTop: '0',
        paddingBottom: '0',
    }
  });

function SelectCompany({color, sx, variant}) {
    const { l, language } = useI18n();
    const [companies, setCompanies] = React.useState();
    const [image, setImage] = React.useState();
    const navigate = useNavigate();
    const [selectedCompany, setSelectedCompany] = useRecoilState(currentCompanyState);

    React.useEffect(() => {
        const getCompanies = async () => {
            try {
                const companiesData = await companyService.getAllowedCompaniesAsync(language);
                setCompanies(companiesData);
                let company = companiesData.find(c => c.id === sessionStorage.getItem('company'));
                if (!company && companiesData.length > 0) {
                    company = companiesData[0]; 
                    sessionStorage.setItem('company', company.id);
                }
                setSelectedCompany(company);
                if (company) {
                    await getImage(company.id);
                }
            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        };
        getCompanies();
    }, []);

    const getImage = async (id) => {
        try {
            const blob = await companyService.getImageByCompanyId(id);
            if (blob.size < 3) {
                setImage('');
                return;
            }
            const imageUrl = URL.createObjectURL(blob);
            setImage(imageUrl || '');
        } catch (error) {
          console.error('getImage error :>> ', error);
        } 
      };

    const handleChange = (event) => {
        const { value } = event.target;
        const company = companies.find(company => parseInt(company.id) === value);
        setSelectedCompany(company);
        sessionStorage.setItem('company', value);
        getImage(company.id);
        navigate('/devices');
        // Дополнительная логика обработки выбранной компании здесь
    };
    
    return (
        <FormControl variant={variant} sx={sx}>
            {
                companies && selectedCompany?.id ?
                    <CustomSelect
                        value={parseInt(selectedCompany?.id)}
                        defaultValue={parseInt(selectedCompany.id)}
                        onChange={handleChange}
                        sx={{ color: color }}
                    >
                        {companies?.map((company, i) => (
                            <MenuItem key={company.id} value={parseInt(company.id)}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {/* {company.id === selectedCompany?.id && image ? <img 
                                    height={'40px'} 
                                    width={'100%'} 
                                    src={image} alt='' /> : ''} */}
                                    <Typography variant="h4">
                                        {company.company_name}
                                    </Typography>
                                </Box>
                            </MenuItem>
                        ))}
                    </CustomSelect>
                    :
                    ''
            }
        </FormControl>
    );
};

export default SelectCompany;