const CellType = {
    NUMBER:  'NUMBER',
    DATE:'DATE',
    BOOLEAN: 'BOOLEAN',
    STRING: 'STRING',
    BOOLCONNECTED: 'BOOLCONNECTED',
    BOOLSTATUS: 'BOOLSTATUS',
    BOOLALARM: 'BOOLALARM',
}

export default CellType;
