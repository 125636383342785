import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import deviceService from '../../../api/device';
import probeService from '../../../api/probe';
import AlarmsType from '../../../constants/alarms';
import useI18n from '../../../hooks/useI18n';
import BasicModal from '../../custom/Modal/BasicModal';
import TabPanel from '../../custom/TabPanel/TabPanel';
import Alarms from '../Alarms/Alarms';
import Probes from '../Probes/Probes';
import DeviceInfo from './DeviceInfo';
import Report from './Report';
import Setting from './Setting';

export default function Device() {
  const { l , language } = useI18n();
  const { id } = useParams();
  const [device, setDevice] = React.useState();
  const [title] = React.useState('');
  const [text] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [image, setImage] = React.useState('');

  React.useEffect(() => {
    const getData = async () => {
      try {
        const result = await deviceService.getFindDeviceById(id, language);
        const probes = await probeService.getFastProbeListByDeviceId({ id: id, lang: language });

        result.probelast = {};
        for (const probe of probes) {
          if (probe.calc_marker) {
            result.probelast[probe.calc_marker] = probe.value_last;
          }
        }
        //getImage(result.id);
        setDevice(result);
      } catch (error) {
        console.error('Error get DEVICE by id :>> ', error);
      }

    };
    getData();
  }, []);

    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      const handleChangeIndex = (index) => {
        setValue(index);
      };

      function a11yProps(index) {
        return {
          id: `action-tab-${index}`,
          'aria-controls': `action-tabpanel-${index}`,
        };
      }

  const handleClose = () => setOpen(false);

  const getImage = async (id) => {
    try {
      const blob = await deviceService.getImageByDeviceId(id);
      const imageUrl = URL.createObjectURL(blob);
      setImage(imageUrl || '');
    } catch (error) {
      console.error('getImage error :>> ', error);
    } 
  };

  return (
    <>
      {open ?
        <BasicModal isOpened={true}
          type="success"
          title={title}
          text={text}
          handle={handleClose}
        /> : ""
      }
      <DeviceInfo/>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
          >
          <Tab label={l("Probes")} {...a11yProps(0)} />
          <Tab label={l("Notification")} {...a11yProps(1)} />
          <Tab label={l("Report")} {...a11yProps(2)} />
          <Tab label={l("Setting")} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Probes deviceId={id} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Box sx={{ padding: '5px 0' }}>
            <Alarms type={AlarmsType.BY_COMPANY_AND_DEVICE} companyId={device?.company_id} deviceId={device?.id} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Report /> 
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Setting device={device} />
        </TabPanel>
      </SwipeableViews>
    </>
  );
}

//https://mui.com/material-ui/react-floating-action-button/
