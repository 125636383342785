import {
    AppBar,
    Box,
    Button,
    Tab,
    Tabs
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import probeService from '../../../api/probe';
import useI18n from '../../../hooks/useI18n';
import TabPanel from '../../custom/TabPanel/TabPanel';
import Graphics from '../Graphics/Graphics';
import Setting from './Setting';

export default function Probe() {
    const { l, language } = useI18n();
    const [probes, setProbes] = React.useState([]);
    const navigate = useNavigate();
    const { probeId, deviceId } = useParams();
    const [probeIdIndecator, setProbeIdIndecator] = React.useState(probeId);
    const [value, setValue] = React.useState(0);
    
    React.useEffect(() => {
        const getData = async () => {
            try {
                const result = await probeService.getFastProbeListByDeviceId({ id: deviceId});
                setProbes(result);
            } catch (error) {
                console.error('error getFastProbeListByDeviceId :>> ', error);
            }
        };
        getData();
    }, [probeIdIndecator]);

    const theme = useTheme();

    const backToProbsList = () => {
        navigate(`/device/${deviceId}`);
    }

    const goNextProbe = () => {
        let index = probes.findIndex(probe => probe.id === probeIdIndecator);
        index = index === probes.length - 1 ? 0 : index + 1;
        navigate(`/device/${deviceId}/probe/${probes[index]?.id}`)
        setProbeIdIndecator(probes[index]?.id);
    }

    const goPreviousProbe = () => {
        let index = probes.findIndex(probe => probe.id === probeIdIndecator);
        index = index === 0 ? probes.length - 1 : index - 1;
        navigate(`/device/${deviceId}/probe/${probes[index]?.id}`)
        setProbeIdIndecator(probes[index]?.id);
    }
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    function a11yProps(index) {
        return {
            id: `action-tab-${index}`,
            'aria-controls': `action-tabpanel-${index}`,
        };
    }

    return (
        <>
        <Box mb={3} sx={{mt: "20px"}} display="flex" justifyContent="flex-start">
            <Button sx={{mr: 2}}
                    variant="outlined"
                    size="small"
                    onClick={backToProbsList}>
                    {l('Back to probes')}
            </Button>
            <Button sx={{mr: 2}}
                    variant="outlined"
                    size="small"
                    onClick={goPreviousProbe}
                    // disabled={clicks < 1}
                    >
                    {l('Previous probe')}
            </Button>
            <Button variant="outlined"
                    size="small"
                    onClick={goNextProbe}
                    // disabled={clicks >= +probes.length - 1}
                    >
                    {l('Next probe')}
            </Button>
        </Box>
        <AppBar position="static" color="default">
            <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="action tabs example"
            >
            <Tab label={l("Graphics")} {...a11yProps(0)} />
            <Tab label={l("Setting")} {...a11yProps(1)} />
            </Tabs>
        </AppBar>
        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
            >
            <TabPanel  value={value} index={0} dir={theme.direction}>
            <Box sx={{overflow:'hidden'}}>
                <Graphics probeId={probeIdIndecator} />
            </Box>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <Setting />
            </TabPanel>
        </SwipeableViews>
        </>
    );
}