import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import probeService from '../../../api/probe';
import CellType from '../../../constants/type';
import useI18n from '../../../hooks/useI18n';
import TableData from '../../custom/Table/TableData';


export default function Probes({deviceId}) {
    const { language, l } = useI18n();

    const columns = [
        { id: 'comment', label: l('Description'), minWidth: 60, type: CellType.STRING },
        { id: 'status_flag', label: l('Status'), minWidth: 60, type: CellType.BOOLSTATUS, align: 'center' },
        { id: 'updated_at', label: l('Time/Date data updating'), minWidth: 120, type: CellType.DATE, align: 'center' },
        { id: 'value_last', label: l('Latest Value'), minWidth: 60, type: CellType.NUMBER , align: 'center'},
        { id: 'value_average', label: l('Average Value'), minWidth: 60, type: CellType.NUMBER , align: 'center'},
        { id: 'value_min', label: l('Min. Value'), minWidth: 60, type: CellType.NUMBER , align: 'center'},
        { id: 'value_max', label: l('Max. Value'), minWidth: 60, type: CellType.NUMBER , align: 'center'},
        { id: 'limit_l_crit', label: l('LL'), minWidth: 60, type: CellType.NUMBER , align: 'center'},
        { id: 'limit_l', label: l('L'), minWidth: 60, type: CellType.NUMBER , align: 'center'},
        { id: 'limit_h', label: l('H'), minWidth: 60, type: CellType.NUMBER , align: 'center'},
        { id: 'limit_h_crit', label: l('HH'), minWidth: 60, type: CellType.NUMBER, align: 'center'}
      ];

    const [probes, setProbes] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        const getDataProbes = async () => {
            try {
                const result = await probeService.getFastProbeListByDeviceId({ id: deviceId });

                const probesWithSorting = result.filter(item => item.sorting !== undefined);
                const probesWithoutSorting = result.filter(item => item.sorting === undefined);

                probesWithSorting.sort((a, b) => a.sorting - b.sorting);
                probesWithoutSorting.sort((a, b) => a.id - b.id);
                const sortedProbes = [...probesWithSorting, ...probesWithoutSorting].map(probe => ({
                    ...probe,
                    comment: l(probe.comment)
                }));
                setProbes(sortedProbes);
            } catch (error) {
                console.error('Error fetching probes:', error);
            }
        };

        getDataProbes();
    
        const interval = setInterval(getDataProbes, 10000);
        return () => clearInterval(interval);
    }, []);

    function setClickRow (params) {
        navigate(`/device/${deviceId}/probe/${params.id}`);
    }

    return (
        <>
            <TableData columns={columns} rows={probes} isPointCursor={true}  setClickRow={setClickRow} />
        </>
    );
}
