import * as React from 'react';
import useI18n from '../../../hooks/useI18n';
import { Modal, TextField, Select, MenuItem, FormControl, InputLabel, Button, Box, Typography, FormHelperText } from '@mui/material';
import DatePicker  from '../../custom/DatePicker/DatePicker';
import moment from 'moment';
import roleService from '../../../api/role';
import companyService from '../../../api/company';
import accessTokenService from '../../../api/accessToken';

export default function AddAccessToken ({ onSubmitSuccess, companyId }) {
    const { l, language } = useI18n();
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [allowedRoles, setAllowedRoles] = React.useState([]);
    const [companies, setCompanies] = React.useState('');
    const [companyIdValue, setCompanyIdValue] = React.useState(null);
    const [userRoleValue, setRoleValue] = React.useState(null);
    const [validTo, setValidTo] = React.useState(moment().add(1, 'days').format('YYYY-MM-DD HH:mm'));
    const [isValidCompany, setIsValidCompany] = React.useState(true);
    const [isValidRole, setIsValidRole] = React.useState(true);
    const [isValidEmail, setIsValidEmail] = React.useState(true);

    const handleOpen = () => {
        const getAllowedRoles = async () => {
            try {
                const result = await roleService.getAllowedRolesAsync(language);
                setAllowedRoles(result.map(role => {return { user_role: role.user_role, comment: role.comment };}));
            } catch (error) {
                console.error('Error fetching getAllowedRoles: ', error);
            }
        }
        getAllowedRoles();

        const getCompaniesId = async () => {
            try {
                const result = await companyService.getAllowedCompaniesAsync(language); 
                setCompanies(result.map(company => {return { id: company.id, name: company.company_name };}));
                // setCompaniesId(result.map(company => company.id));
            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        };
        getCompaniesId()
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const handleSubmit = async(event) => {
        event.preventDefault();
        setIsValidCompany(companyIdValue);
        setIsValidRole(userRoleValue);
        setIsValidEmail(email && !validateEmail(email));
        if (validateEmail(email)) {
            try {
                const result = await accessTokenService.createAccessTokenAsync({
                    data: {
                        email: email,
                        companyId,
                        userRole: userRoleValue,
                        validTo
                    }, 
                    lang: language 
                });
                if (result) onSubmitSuccess();
                setOpen(false);
            } catch (error) {
                console.error('Error fetching users:', error);
            } 
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'email') setEmail(value);
        if (name === 'user_role') setRoleValue(value);
        if (name === 'company_id') setCompanyIdValue(value);
    }

    function handleCancel () {
        setOpen(false); 
    }

    const validateEmail = (email) => {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return regex.test(email);
    }

    return (
        <>
            <Button size="small" variant="contained" onClick={handleOpen}>{l('Add Access Token')}</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box 
                    display={'flex'}
                    gap={2}
                    component="form"
                    width={300}
                    sx={{ '& .MuiTextField-root': { width: '100%' } }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    className="modal">
                    <Typography id="modal-modal-title" variant="h6" color='#1976d2' component="h2" sx={{fontSize: 24}}>
                        {l('Add Access Token')}
                    </Typography>
                    <Box width={'100%'} display='flex' flexDirection='row' alignItems='center'>
                        <TextField
                            name="email"
                            required
                            id="email"
                            label={l('User Email')}
                            onChange={handleInputChange}
                            variant="outlined"
                            size="small"
                            style={{ width: '100%' }}
                            error={!isValidEmail}
                            helperText={(!isValidEmail) && l('Email is invalid')}
                        />
                    </Box>
                    <Box width={'100%'} display='flex' flexDirection='row' alignItems='center'>
                        <TextField
                            name="company_id"
                            id="company_id"
                            label={l('Company')}
                            variant="outlined"
                            size="small"
                            style={{ width: '100%' }}
                            InputProps={{ readOnly: true }}
                            value={companies ? companies.find(company => company.id === companyId)?.name : ''} 
                        />
                    </Box>
                    <FormControl sx={{ width: '100%' }} size="small">
                        <InputLabel id="demo-select-small-label">{l('User Role') + ' *'}</InputLabel>
                        <Select
                            labelId="user-role-select-label"
                            id="user-role-select"
                            name="user_role"
                            label={l('User Role')}
                            value={userRoleValue || ''}
                            onChange={handleInputChange}
                            error={!isValidRole}
                            >
                            {allowedRoles ? allowedRoles.map((role) => (
                                <MenuItem value={role.user_role} key={role.user_role}>{role.comment}</MenuItem> )) : ""
                            }
                        </Select>
                        <FormHelperText sx={{color: 'red'}}>{!isValidRole && l('Field is required')}</FormHelperText>
                    </FormControl>
                    <DatePicker 
                      onChangeDate={(date) => {setValidTo(date.format('YYYY-MM-DD HH:mm'))}} 
                      sx={{width: '100%', '& .MuiInputBase-root': {height : 41}}} 
                      lable={l('Valid to')} 
                      defaultDate={moment().add(1, 'days').format('YYYY-MM-DD HH:mm')}
                    />
                    <Box  width={'100%'} display={'flex'} gap={1}>
                        <Button fullWidth type="submit" size="small" variant="contained" color='success'>{l('Create')}</Button>
                        <Button fullWidth onClick={handleCancel} size="small" variant="contained" color='error'>{l('Cancel')}</Button>
                    </Box>
                </Box>
            </Modal>
        </>
      );
}