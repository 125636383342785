import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import Avatar from '@mui/material/Avatar';
import { useTimer } from 'react-timer-hook';
import { loginState } from '../../../state/login';
import { useRecoilState } from "recoil";
import * as React from 'react';

import { IMaskInput } from 'react-imask';
import useI18n from '../../../hooks/useI18n';
import useLogin from '../../../hooks/useLogin';
import { useNavigate } from 'react-router-dom';
  
const CodeMask = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0-0-0-0"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});



function VerificationPage(props) {
    const {l} = useI18n();
    const [values, setValues] = React.useState({
      codemask: '',
      numberformat: '1320',
    });
    const [loginData, loginDataState] = useRecoilState(loginState);
    const { authenticationEmail, error, setError } = useLogin();
    const navigate = useNavigate();
    const currentDate = new Date();
    const {
      seconds,
      minutes,
      isRunning,
      start,
      pause,
      resume,
      restart,
    } = useTimer({
      expiryTimestamp: new Date(currentDate.getTime() + loginData.timer * 60000), 
      onExpire: () => { 
        navigate('/login');
        loginDataState({email: '', timer: 1});
      },
      autoStart: true
    });

    React.useEffect(() => {
        if (error) setValues({codemask: '', numberformat: '1320'});
    }, [error]);

    React.useEffect(() => {
      if (!loginData.email) navigate('/login')
    }, []);

    const handleAuth = () => {
      if (error) setError('');
      authenticationEmail({ email: loginData.email, code: values.codemask.replace(/-/g, '') })  
    };
  
    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    };

  return (
    <Container component="main" maxWidth="xs" sx={{height: '70vh'}}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.light' }}>
          <SafetyCheckIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {l('Verification')}
        </Typography>
        <Box sx={{ padding: '16px 24px' }}>
          <Typography fontSize={50} align='center' >
            {minutes.toString().padStart(2, '0')}
            :
            {seconds.toString().padStart(2, '0')}
          </Typography>
          <FormControl variant="standard">
            <InputLabel>{l("Code")}</InputLabel>
            <Input
              sx={{
                '& input': {
                  textAlign: 'center',
                  border: 0,
                  fontSize: '1.5rem',
                }
              }}
              variant="standard"
              value={values.codemask}
              placeholder="X-X-X-X"
              defaultValue="X-X-X-X"
              onChange={handleChange}
              name="codemask"
              inputComponent={CodeMask}
              autoComplete='off'
              autoSave='off'
            />
            <Typography color={'red'}>
              {error ? error : ''}
            </Typography>
            <Button
              disabled={values.codemask.replace(/-/g, '').length !== 4 && !isRunning}
              sx={{ marginTop: '10px' }}
              variant='contained'
              onClick={() => handleAuth()}>
              {l('Confirm')}
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Container>
  );
};

export default VerificationPage;
