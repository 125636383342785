import React from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

function DatePickerTime({defaultDate, lable, onChangeDate, sx}) {
    const [date, setDate] = React.useState(defaultDate);
    
    return (
        <Box sx={sx}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DateTimePicker inputRef={(e) => { e?.setAttribute('disabled', 'true') }} key={'from'} ampm={false} format='DD.MM.YYYY HH:mm' onAccept={onChangeDate} defaultValue={dayjs(date)} sx={{ width: '50%' }} label={lable} />
                </Box>
            </LocalizationProvider>
        </Box>
    );
}

export default DatePickerTime;