import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export default function SlideBarButtonList(prop) {
  const { buttons, open, activePage, user } = prop;
  return (
    < List >
      {
        buttons.map((btn, index) => btn.role === user?.user_role || !btn?.role ? (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={btn?.onClick}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
                className={btn.text === activePage ? 'active-button' : ""}
              >
                {btn?.icon}
              </ListItemIcon>
              <ListItemText primary={btn?.text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ): '')
      }
    </List >
  );
}
