import useI18n from '../../../hooks/useI18n';
import moment from 'moment';

export default function CustomTooltip({ payload, active }) {
  const { l } = useI18n();
  if (payload && payload.length) {
  const time = moment(payload[0].payload.date).format('HH:mm');
  const day = moment(payload[0].payload.date).format('DD.MM.YYYY');

    return (
      <div className="custom-tooltip">
        <p className="label" style={{fontSize: "10px"}}>{`${l('Date')}: ${day} ${l('Time')}: ${time}`}</p>
        { payload.map((line) => (
          <p key={line.name} className="label" style={{fontSize: "10px"}}>{`${line.name}: ${line.value}`}</p> ))
        }
      </div>
    );
  }
  return null;
}