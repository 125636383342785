import React from 'react';
import { Box,
         FormControlLabel,
         Checkbox } from '@mui/material';

export default function CheckboxesBox ({probes, probe, handleCheckboxChange, toCompare}) {
    const [newProbes, setNewProbes] = React.useState();

    React.useEffect(() => {
      const data = probes?.reduce((acc,probe) => {
        if (probe.is_coil === null) {
          acc.push(probe)
        }
        return acc
      }, [])
      setNewProbes(data);
    }, [probes])

    const column1 = newProbes?.slice(0, Math.ceil(newProbes.length / 2)).map( (currentProbe) => {
      return <FormControlLabel
      key={currentProbe.id}
                control={
                <Checkbox key={currentProbe.comment}
                          id={currentProbe.id}
                          checked={((probe.id === currentProbe.id) || toCompare[currentProbe.comment]) ? true : false}
                          disabled={(probe.id === currentProbe.id)}
                          onChange={handleCheckboxChange}
                          name={currentProbe.comment} />
                }
              label={`${currentProbe.comment}`}
              />
    });
    const column2 = newProbes?.slice(Math.ceil(newProbes.length / 2)).map( (currentProbe) => {
      return <FormControlLabel key={currentProbe.id}
                control={
                <Checkbox key={currentProbe.comment}
                          id={currentProbe.id}
                          checked={((probe.id === currentProbe.id) || toCompare[currentProbe.comment]) ? true : false}
                          disabled={(probe.id === currentProbe.id)}
                          onChange={handleCheckboxChange}
                          name={currentProbe.comment} />
                }
              label={`${currentProbe.comment}`}
              />
    });
    return (
        <Box display="flex" flexDirection="row" gap={4}>
          <Box display="flex" flexDirection="column">{column1}</Box>
          <Box display="flex" flexDirection="column">{column2}</Box>
        </Box>
      )
  }