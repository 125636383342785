import { getData, postData, getImage } from "../lib/fetchApi";
import { COMPANY_URL_URLS } from "../constants/urls";
import { defaultLocale } from "../config";

const companyService = {
    getCompanyListAsync: (lang = defaultLocale) => {
        return getData({ url: COMPANY_URL_URLS.FIND_ALL, lang })
    },
    getAllowedCompaniesAsync: (lang = defaultLocale) => {
        return getData({ url: COMPANY_URL_URLS.FIND_ALLOWED_COMPANIES, lang })
    },
    findCompanyByIdExtendedAsync: (id, lang = defaultLocale) => {
        return getData({ url: COMPANY_URL_URLS.FIND_BY_ID_EXTENDED + id, lang })
    },
    createCompanyAsync: (data, lang = defaultLocale) => {
        return postData({ url: COMPANY_URL_URLS.CREATE, lang, data })
    },
    updateCompanyAsync: ({ id, data, file, type, lang = defaultLocale }) => {
        return postData({ url: COMPANY_URL_URLS.UPDATE + id, lang, data, file, type })
    },
    deleteCompanyAsync: (id, lang = defaultLocale) => {
        return postData({ url: COMPANY_URL_URLS.DELETE + id, lang })
    },
    getImageByCompanyId: (data, lang = defaultLocale) => {
        return getImage({ url: COMPANY_URL_URLS.GET_IMAGE_BY_DEVICE_ID + data, lang })
    }
}
export default companyService;