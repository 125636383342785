import { postData } from "../lib/fetchApi";
import { PUBLIC_URLS } from "../constants/urls";
import { defaultLocale } from "../config";

const publicService = {
    loginUserAsync: (data ,lang = defaultLocale) => {  
        return postData({ url: PUBLIC_URLS.LOGIN, lang, data })   
    },
    forgotPasswordAsync: ({ data, lang = defaultLocale }) => {  
        return postData({ url: PUBLIC_URLS.FORGOT_PASSWORD, lang, data })   
    },
    authenticationEmail: (data ,lang = defaultLocale) => {  
        return postData({ url: PUBLIC_URLS.AUTHENTICATION_EMAIL, lang, data })   
    },
}
export default publicService;