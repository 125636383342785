import * as React from 'react';
import userService from '../../../api/user';
import CellType from '../../../constants/type';
import useI18n from "../../../hooks/useI18n";
import TableData from '../../custom/Table/TableData';
import Typography from '@mui/material/Typography';
import { Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import AddUser from './AddUser';
import useUserInfo from '../../../hooks/useUserInfo';

export default function Users() {
    const { language, l } = useI18n();

    const columns = [
        { id: 'email', label: l('Email'), minWidth: 150, type: CellType.STRING },
        { id: 'company_name', label: l('Company Name'), minWidth: 100, type: CellType.STRING },
        { id: 'user_name', label: l('Name'), minWidth: 100, type: CellType.STRING },
        { id: 'is_active', label: l('Is Active'), minWidth: 50, type: CellType.BOOLEAN },
        { id: 'phone', label: l('Phone'), minWidth: 100, type: CellType.STRING },
        { id: 'comment', label: l('User Role'), minWidth: 150, type: CellType.STRING },
        { id: 'created_at', label: l('Created At'), minWidth: 150, type: CellType.DATE },
    ];
    
    const [users, setUsers] = React.useState();
    const navigate = useNavigate();
    const {user: currentUser} = useUserInfo();

    const getUserList = async () => {
        try {
            const users = await userService.findAllForCompanyAsync(sessionStorage.getItem('company'), language);
            if(users) setUsers(users?.sort((a, b) => a.id - b.id));

        } catch (error) {
            console.error('Error getUserList :>> ', error);
        }
    };

    React.useEffect(() => {
        getUserList();
    }, []);

    function setClickRow(params) {
        navigate(`/user/${params.id}`);
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" margin={'10px 0'}>
                <Typography component="h2" variant="h5">
                    {l('Users')}
                </Typography>
                {
                    currentUser?.isAllowCreateUsers ?
                        <AddUser users={users} onSubmitSuccess={getUserList} />
                        :
                        ""
                }
            </Box>
            <TableData isPointCursor={true} setClickRow={setClickRow} columns={columns} rows={users} />
        </>
    );
}