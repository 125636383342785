
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { IconButton, InputAdornment } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useI18n from "../../../hooks/useI18n";
import SelectLanguage from '../../custom/SelectLanguage/SelectLanguage';

import LogoRegular from '../../../assets/logoRegular';
import useLogin from '../../../hooks/useLogin';

// TODO remove, this demo shouldn't need to reset the theme.

export default function SignIn() {
  const { l, language } = useI18n();
  const { login, error } = useLogin();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleTogglePassword = () => {
    setShowPassword(show => !show);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      login: data.get('login'),
      password: data.get('password'),
    }
    await login(userData);
  };

  const handleForgotPassword = (event) => {
      event.preventDefault();
      navigate('/forgotPassword');
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <LogoRegular color={'#42A5F5'} height={'97px'} />
        <Avatar sx={{ m: 1, bgcolor: 'primary.light' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {l('Sign in')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="login"
            label={l("Login")}
            name="login"
            autoComplete="login"
            autoFocus
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label={l("Password")}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                  <InputAdornment position='end'>
                      <IconButton onClick={() => handleTogglePassword()} onMouseDown={handleMouseDownPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                  </InputAdornment>
              )
            }}
            id="password"
            autoComplete="current-password"
          />

          <Typography color={'red'}>
            {error ? error : ''}
          </Typography>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            size='large'
            sx={{ mt: 3, mb: 2 }}
          >
            {l('Sign In')}
          </Button>
          <Grid container sx={{justifyContent: 'space-between', alignItems: 'end'}}>
            <Link href="#" variant="body2" onClick={handleForgotPassword}>
              {l('Forgot password') + '?'}
            </Link>
            <SelectLanguage variant={'standard'}/>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
