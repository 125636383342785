import * as React from 'react';

import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import useI18n from '../../../hooks/useI18n'
import { styled } from '@mui/material/styles';
import { languageArray, swappedLanguageArray } from '../../../i18n/selectLang';

const CustomSelect = styled(Select)({
    '&.MuiOutlinedInput-root': {
      border: 'none', // Видаляємо бордер
      '& fieldset': {
        border: 'none', // Видаляємо бордер
      },
      '&:hover fieldset': {
        border: 'none', // Видаляємо бордер при наведенні
      },
      '&.Mui-focused fieldset': {
        border: 'none', // Видаляємо бордер при фокусі
      },
      '& .MuiSvgIcon-root': {
        color: 'white', // Змінюємо колір іконки
      }
    },
  });

function SelectLanguage({color, sx, variant}) {
  const {changeLanguage, language} = useI18n();
  const [lang, setLenguage] = React.useState(swappedLanguageArray[language]);

  const handleChange = (event) => {
      changeLanguage(languageArray[event.target.value]);
      setLenguage(event.target.value);
  };

  return (

      <FormControl variant={variant} sx={sx}>
          <CustomSelect
              value={lang}
              defaultValue={lang}
              label="Leguage"
              onChange={handleChange}
              sx={{ 
                color: color, 
                }}
          >
              {Object.keys(languageArray).map(k =>
                  <MenuItem key={k} value={parseInt(k)}>{languageArray[k].toUpperCase()}</MenuItem>
              )}
          </CustomSelect>
      </FormControl>
  );
};

export default SelectLanguage;