import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useI18n from '../../../hooks/useI18n';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function InputFileUpload({sx, onChange, accept, name}) {
    const { l } = useI18n();

    return (
        <Button
            sx={sx}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            onChange={onChange}
            startIcon={<CloudUploadIcon />}
        >
            {l("Upload image")}
            <VisuallyHiddenInput name={name} accept={accept} type="file" />
        </Button>
    );
}