import { Box, Button, Divider, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import React from 'react';
import userService from '../../../api/user';
import useI18n from '../../../hooks/useI18n';
import useUserInfo from '../../../hooks/useUserInfo';
import BasicModal from '../../custom/Modal/BasicModal';
import PhoneInput from '../../custom/PhoneInput/PhoneInput';

function UserSetting() {
    const {l, language} = useI18n();
    const { user, getCurrentUser } = useUserInfo();
    const [open, setOpen] = React.useState(false);
    const [userSetting, setUserSetting] = React.useState();

    React.useEffect(() => {
        const getData = async () => {
        const currentUser = getCurrentUser();   
         const result = await userService.findById(currentUser.id, language);
         setUserSetting(result);
        }
        getData();
    }, []);

    const handleSubmit = async(event) => {
        event.preventDefault();

        if(userSetting?.phone === '') {
            setOpen(true);
            return;
        }

        if (user?.id) await userService.updateUserAsync({ id: user.id, data: userSetting, lang: language });
      }

      const handleOnChange = (event) => {
        let { name, checked, value } = event.target;

        if(name === 'phoneNumber'){
            setUserSetting({
                ...userSetting,
                'phone': value
            })
            return;
        }
        
        setUserSetting({
          ...userSetting,
          [name]: checked
        })
      }

    return (userSetting ?
        <Box 
        component={'form'}  
        onSubmit={handleSubmit} 
        margin={'0 auto'} 
        padding={2} 
        borderRadius={2} 
        sx={{backgroundColor: 'white'}}>
            {open ?
                <BasicModal isOpened={true}
                    type="warning"
                    title={l('Warning')}
                    text={l('Phone number is required') + '!'}
                    handle={() => setOpen(false)}
                /> : ""
            }
            <Typography component="h2" variant="h5">{l('User setting')}</Typography>
            <Divider />
            <PhoneInput value={userSetting?.phone} name="phoneNumber" sx={{margin: '10px 0'}} handleChange={handleOnChange}/>
            <Button sx={{mt: 2, display:'flex', flexGrow: 1}} type="submit" size="small" variant="contained" color='primary'>{l('Save')}</Button>
        </Box> : '');
}

export default UserSetting;