import * as React from 'react';
import useI18n from '../../../hooks/useI18n';
import { 
    Modal, 
    Button, 
    Box, 
    Typography, 
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from '@mui/material';
import probeService from '../../../api/probe';

export default function ExportGraphic ({ probeId, from, to }) {
    const { l, language } = useI18n();
    const [open, setOpen] = React.useState(false);
    const [formatData, setFormatData] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const formats = ['csv', 'xlsx', 'json'];

    const getProbeFile = async ({ id, from, to, format, lang }) => {
        try {
            const exportContent = await probeService.exportProbeAsync({ id, data: { from, to, format }, lang });
            return exportContent.data;
        } catch (error) {
            console.error('Error fetching getProbeInfo: ', error);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { fileContent, fileName } = await getProbeFile({ id: probeId, from, to, format: formatData, lang: language });
    
        let content;
        switch (formatData) {
            case 'csv':
                content = new Blob([fileContent], { type: 'text/csv;charset=utf-8;' });
                break;
            case 'xlsx':
                const uint8Array = new Uint8Array(fileContent.data);
                content = new Blob([uint8Array], { type: 'application/octet-stream' });
                break;
            case 'json':
                content = new Blob([fileContent], { type: 'application/json' });
                break;
            default:
                break;
        }
        
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(content);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log("Ваш браузер не поддерживает скачивание файлов.");
        }
        
        setOpen(false);
    }

    function handleCancel () {
        setOpen(false); 
    }

    const handleInputChange = (event) => {
        const { value } = event.target;
        setFormatData(value);
    }

    return (
        <>
            <Button size="large" style={{ marginLeft: 'auto' }} variant="contained" onClick={handleOpen}>{l('Export Graphic')}</Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
            <Box 
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                className="modal">
                <Typography id="modal-modal-title" variant="h6" color='#1976d2' component="h2" sx={{fontSize: 24}}>
                    {l('Export Graphic')}
                </Typography>
                <Box display="flex"
                    flexDirection='column'
                    alignItems="center"
                    justifyContent="space-between"
                    mb={2}>
                </Box>
                <FormControl sx={{ m: 1, width: '70%' }} size="small">
                    <InputLabel id="demo-select-small-label">{l('Format')}</InputLabel>
                    <Select
                        labelId="format-select-label"
                        id="format-select"
                        name="format"
                        label={l("Format")}
                        value={formatData}
                        onChange={handleInputChange}
                        >
                        {formats.map((format) => (
                            <MenuItem value={format} key={format}>
                                {format}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{mt: 3}}>
                    <Button sx={{mr: 3}} type="submit" size="small" variant="contained" color='success'>{l('Export')}</Button>
                    <Button onClick={handleCancel} size="small" variant="contained" color='error'>{l('Cancel')}</Button>
                </Box>
            </Box>
            </Modal>
        </>
    );
}