import * as React from 'react';
import useI18n from '../../../hooks/useI18n';
import { Box,
         Button,
         Typography,
         Modal,
         TextField,
         FormControlLabel,
         Checkbox } from '@mui/material';
import roleService from '../../../api/role';


export default function AddRole ({ onSubmitSuccess }) {
    const { l, language } = useI18n();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [role, setRole] = React.useState({});

    const handleSubmit = async (event) => {
      event.preventDefault();
      setOpen(false);
      try {
        await roleService.createRoleAsync(role, language);
        onSubmitSuccess();
      } catch (error) {
          console.error('Error create role:', error);
      } finally {
        setOpen(false);
      }
    }

    const handleInputBlur = (event) => {
      const { name, value } = event.target;
      setRole({
        ...role,
        [name]: value
      })
    }

    const handleInputChange = (event) => {
        const { name } = event.target;
        if (event.target.checked) {
          setRole({
            ...role,
            [name]: true
          })
        } else if (!event.target.checked) {
            setRole({
            ...role,
            [name]: false
          })
        }
    }

    function handleCancel () {
      setOpen(false); 
    }

    return (
      <>
      <Button size="small" variant="contained" onClick={handleOpen}>{l('Add role')}</Button>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box
          component="form"
          sx={{ m: 1, width: "80%" }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          className="modal">
          <Typography id="modal-modal-title" variant="h6" color='#1976d2' component="h2" sx={{ fontSize: 24 }}>
            {l('Add role')}
          </Typography>
          <Box display="flex"
            flexDirection='column'
            mb={2}>
            <Box display="flex"
              flexDirection='row'
              alignItems="center"
              justifyContent="flex-start"
              gap='20px'>
              <TextField name="user_role"
                id="user_role"
                label={l("User Role")}
                onBlur={handleInputBlur}
                variant="outlined"
                size="small" />
              <TextField name="comment"
                id="comment"
                label={l("Comment")}
                onBlur={handleInputBlur}
                variant="outlined"
                size="small" />
            </Box>
            <Box display="flex" flexDirection='row' sx={{ flexWrap: 'wrap' }}>
              <Box display="flex" flexDirection='column'>
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowCreateCompanies" 
                  name="isAllowCreateCompanies" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to create companies")} />
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowCreateDevice" 
                  name="isAllowCreateDevice" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to create devices")} />
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowCreateRoles" 
                  name="isAllowCreateRoles" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to create roles")} />
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowCreateUsers" 
                  name="isAllowCreateUsers" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to create users")} />
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowCreateAccessTokens" 
                  name="isAllowCreateAccessTokens" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to create access tokens")} />
              </Box>
              <Box display="flex" flexDirection='column'>
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowEditCompanies" 
                  name="isAllowEditCompanies" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to edit companies")} />
                <FormControlLabel control={
                  <Checkbox
                  id="isAllowEditDevices" 
                  name="isAllowEditDevices" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to edit devices")} />
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowEditRoles" 
                  name="isAllowEditRoles" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to edit roles")} />
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowEditUsers" 
                  name="isAllowEditUsers" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to edit users")} />
              </Box>
              <Box display="flex" flexDirection='column'>
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowDeleteCompanies" 
                  name="isAllowDeleteCompanies" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to delete companies")} />
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowDeleteDevices" 
                  name="isAllowDeleteDevices" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to delete devices")} />
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowDeleteRoles" 
                  name="isAllowDeleteRoles" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to delete roles")} />
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowDeleteUsers" 
                  name="isAllowDeleteUsers" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to delete users")} />
                <FormControlLabel control={
                  <Checkbox 
                  id="isAllowDeleteAccessTokens" 
                  name="isAllowDeleteAccessTokens" 
                  onChange={handleInputChange} 
                  size="small" />
                } label={l("Allow to delete access tokens")} />
              </Box>
            </Box>
          </Box>
          <Box>
            <Button sx={{ mr: 3 }} type="submit" size="small" variant="contained" color='success'>{l('Save')}</Button>
            <Button onClick={handleCancel} size="small" variant="contained" color='error'>{l('Cancel')}</Button>
          </Box>
        </Box>
      </Modal>
    </>
      );
}