import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { LanguageProvider } from './i18n/LanguageContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter basename="">
		<RecoilRoot>
			<LanguageProvider>
				<App />
			</LanguageProvider>
		</RecoilRoot>
	</BrowserRouter>
);
