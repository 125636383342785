import { atom } from "recoil";

const loginState = atom({
    key: "loginState",
    default: { 
        email: "",
        timer: 3
    },
});


export { loginState };