import * as React from 'react';
import roleService from '../../../api/role';
import useI18n from '../../../hooks/useI18n';
import TableData from '../../custom/Table/TableData'
import CellType from '../../../constants/type';
import Typography from '@mui/material/Typography';
import AddRole from './AddRole';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useUserInfo from '../../../hooks/useUserInfo';

export default function Roles() {
    const { language, l } = useI18n();

    const columns = [
        { id: 'comment', label: l('Comment'), minWidth: 200, type: CellType.STRING },
        { id: 'created_at', label: l('Created At'), minWidth: 200, type: CellType.DATE },
        { id: 'user_role', label: l('User Role'), minWidth: 60, type: CellType.STRING },
        { id: 'isAllowCreateCompanies', label: l('Allow Create Companies'), minWidth: 60, type: CellType.BOOLEAN },
        { id: 'isAllowCreateDevices', label: l('Allow Create Devices'), minWidth: 60, type: CellType.BOOLEAN },
        { id: 'isAllowCreateRoles', label: l('Allow Create Roles'), minWidth: 60, type: CellType.BOOLEAN },
        { id: 'isAllowCreateUsers', label: l('Allow Create Users'), minWidth: 60, type: CellType.BOOLEAN },
        { id: 'isAllowDeleteCompanies', label: l('Allow Delete Companies'), minWidth: 60, type: CellType.BOOLEAN },
        { id: 'isAllowDeleteDevices', label: l('Allow Delete Devices'), minWidth: 60, type: CellType.BOOLEAN },
        { id: 'isAllowDeleteRoles', label: l('Allow Delete Roles'), minWidth: 60, type: CellType.BOOLEAN },
        { id: 'isAllowDeleteUsers', label: l('Allow Delete Users'), minWidth: 60, type: CellType.BOOLEAN },
        { id: 'isAllowEditCompanies', label: l('Allow Edit Companies'), minWidth: 60, type: CellType.BOOLEAN },
        { id: 'isAllowEditDevices', label: l('Allow Edit Devices'), minWidth: 60, type: CellType.BOOLEAN },
        { id: 'isAllowEditRoles', label: l('Allow Edit Roles'), minWidth: 60, type: CellType.BOOLEAN },
        { id: 'isAllowEditUsers', label: l('Allow Edit Users'), minWidth: 60, type: CellType.BOOLEAN }
    ];    

    const [roles, setRoles] = React.useState(null);
    const navigate = useNavigate();
    const {user} = useUserInfo();
    

    const getDataRoles = async () => {
        try {
            const result = await roleService.getRoleListAsync(language); 
            setRoles(result);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    React.useEffect(() => {
        getDataRoles();
    }, []);

    if (!roles?.length) {
        return <div>{l('Loading') + '...'}</div>;
    }

    function setClickRow(params) {
        navigate(`/role/${params.user_role}`);
    }

    return (
        <>
            <Box display="flex"
                 flexDirection='row'
                 alignItems="center"
                 justifyContent="space-between"
                 margin={'10px 0'}>
                <Typography component="h2" variant="h5">
                    {l('Roles')}
                </Typography>
                {
                    user?.isAllowCreateRoles ?
                    <AddRole onSubmitSuccess={getDataRoles} /> 
                    :
                    ""
                }
            </Box>
            <TableData isPointCursor={true} columns={columns} setClickRow={setClickRow} rows={roles} />
        </>
    );
}
