import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import useI18n from '../../../hooks/useI18n';
import DatePickerRange from "../../custom/DatePickerRange/DatePickerRange";
import moment from 'moment';
import { useParams } from 'react-router-dom';
import probeService from '../../../api/probe';
import deviceService from '../../../api/device';
import PDFViewer from '../../custom/PDFViewer/PDFViewer';
import CircularProgress from '@mui/material/CircularProgress';


export default function Report() {
    const { language, l } = useI18n();
    const { id } = useParams();
    const [from, setStartDate] = React.useState('');
    const [to, setEndDate] = React.useState('');
    const [pdfBlob, setPdfBlob] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const startDate = moment().subtract(7, 'days').startOf('day');//.format('YYYY-MM-DD HH:mm Z');
        const endDate = moment().endOf('day');//.format('YYYY-MM-DD HH:mm Z');
        setStartDate(startDate);
        setEndDate(endDate);
    }, []);

    const getGenerateReportByDeviceId = async ({ id, from, to, lang }) => {
        try {
            const generatedContent = await probeService.getGenerateReportByDeviceIdAsync({ id, from, to, lang });
            return generatedContent;
        } catch (error) {
            console.error('Error fetching getGenerateReportByDeviceId: ', error);
        }
    }

    const handleGenerateClick = async () => {
        setLoading(true);
        let startDate = from.format('YYYY-MM-DD HH:mm ZZ');
        let endDate = to.format('YYYY-MM-DD HH:mm ZZ');

        try {
            await getGenerateReportByDeviceId({ id, from: startDate, to: endDate, lang: language });
        } catch (error) {
            console.error('Error generating report:', error);
        } finally {
            setLoading(false); 
        }
    }
    
    const handleStartDateChange = (date) => {
        let startDate = date.startOf('day');
        setStartDate(startDate);
    };

    const handleEndDateChange = (date) => {
        let endDate = date.endOf('day');
        setEndDate(endDate);
    };

    return (
        <Box overflow={'hidden'}>
            <Box display={'flex'} flexDirection={'start'} sx={{ gap: 1, padding: 3 }} variant="outlined">
                <DatePickerRange
                    lableStart={l('Start time')}
                    lableEnd={l('End time')}
                    sx={{ maxWidth: '500px' }}
                    onChangeStartDate={handleStartDateChange}
                    onChangeEndDate={handleEndDateChange}
                    defaultStartDate={moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm Z')}
                    defaultEndDate={moment().endOf('day').format('YYYY-MM-DD HH:mm Z')}
                    type='DatePicker'
                    
                />
                {loading && <CircularProgress />}
                <Button size="small" style={{ marginLeft: 'auto' }} variant="contained" onClick={handleGenerateClick}>{l('Send report to mail')}</Button>
            </Box>
                {pdfBlob && <PDFViewer pdfBlob={pdfBlob} />}
        </Box>
    );
};