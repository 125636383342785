
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Typography,
  Divider,
  Switch,
  FormGroup
} from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import companyService from '../../../api/company';
import roleService from '../../../api/role';
import userService from '../../../api/user';
import useI18n from "../../../hooks/useI18n";
import useUserInfo from '../../../hooks/useUserInfo';
import BasicModal from '../../custom/Modal/BasicModal';

export default function User() {
  const {id} = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState();
  const { l, language } = useI18n();
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [text, setText] = React.useState('');
  const [roleValue, setRoleValue] = React.useState(null);
  const [roles, setRoles] = React.useState([]);
  const [companies, setCompanies] = React.useState('');
  const {user: currentUser} = useUserInfo();

  React.useEffect(() => {
    const getData = async () => {
      try {
        const userResult = await userService.findById(id, language);
        setUserData(userResult);

        const roleResult = await roleService.getRoleListAsync(language);
        setRoleValue(roleResult.findIndex(role => role.user_role === userResult.user_role) + 1)
        setRoles(roleResult.map(role => { return { user_role: role.user_role, comment: role.comment } }));
      } catch (error) {
        console.error('Error fetching ROLES:', error);
      }

      try {
        const result = await companyService.getCompanyListAsync(language);
        setCompanies(result
          .map(company => { return { id: company.id, name: company.company_name } })
          .sort((a, b) => a.id - b.id));
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };
    getData();
  }, []);

  const handleClose = () => navigate("/users");

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === 'user_role') {
      setRoleValue(value);
      setUserData({
        ...userData,
        [name]: roles[value - 1].user_role
      })
    } else if (event.target.type === 'checkbox') {
        setUserData({
          ...userData,
          [name]: checked
      })
    } else {
      setUserData({
      ...userData,
      [name]: value
    })
  }}

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const updatedUser = await userService.updateUserAsync({ id: userData.id, data: userData, lang: language });
      if (updatedUser) {
        setOpen(true);
        setTitle(l('User saved'));
        setText(l('Saving was successfull. User saved.'))
      }
    } catch (error) {
        console.error('Error update user:', error);
    }
  }

  const deleteUser = async () => {
    try {
      const deleteUser = await userService.deleteUserAsync(userData.id, language);
      if (deleteUser) {
        setOpen(true);
        setTitle(l('Operation is successful'));
        setText(l('User was deleted successfully') + ".")
      }
    } catch (error) {
        console.error('Error delete user:', error);
    }
  }

  function handleBackButtonClick() {
    navigate('/users');
  }

  return (
    <>
      {
        userData ?
          <Container component="main">
            {open ?
              <BasicModal isOpened={true}
                type="success"
                title={title}
                text={text}
                handle={handleClose} />
              : ""
            }
            <Card variant="outlined">
              <CardContent sx={{ p: 4 }}>
                <Box display="flex" alignItems={'center'} gap={1}>
                  <IconButton onClick={handleBackButtonClick}>
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography component="h1" variant="h5">
                    {`${l("User")} ${userData?.user_name}`}
                  </Typography>
                </Box>
                <Box
                  component="form"
                  sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                  display={'flex'}
                  flexDirection={'column'}
                  gap={1}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Box display={'flex'} gap={1} flexWrap={'wrap'}>
                    <FormControl sx={{ display: 'flex', flexGrow: '1' }} variant="outlined">
                      <FormHelperText id="email">{l("Email")}</FormHelperText>
                      <OutlinedInput
                        id="email"
                        name="email"
                        defaultValue={userData?.email}
                        aria-describedby="Email"
                        size="small"
                        onChange={handleInputChange}
                      />
                    </FormControl>
                    <FormControl sx={{ display: 'flex', flexGrow: '1' }} variant="outlined">
                      <FormHelperText id="phone">{l("Phone")}</FormHelperText>
                      <OutlinedInput
                        id="phone"
                        name="phone"
                        defaultValue={userData?.phone}
                        aria-describedby="phone"
                        size="small"
                        onChange={handleInputChange}
                      />
                    </FormControl>
                  </Box>
                  <Box display={'flex'} gap={1} flexWrap={'wrap'}>
                    <FormControl sx={{ width: 'calc(50% - 4px)' }} variant="outlined">
                      <FormHelperText id="name">{l("Name")}</FormHelperText>
                      <OutlinedInput
                        id="user_name"
                        name="user_name"
                        defaultValue={userData?.user_name}
                        aria-describedby="name"
                        size="small"
                        onChange={handleInputChange}
                      />
                    </FormControl>
                    <FormControl sx={{ width: 'calc(50% - 4px)' }} size="small">
                      <FormHelperText id="roles">{l('Role')}</FormHelperText>
                      <Select
                        id="user_role"
                        name="user_role"
                        value={roleValue || ''}
                        onChange={handleInputChange}
                      >
                        {roles ? roles?.map((role, i) => (
                          <MenuItem value={i + 1} key={role.user_role}>{role.comment}</MenuItem>)) : ""
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box display={'flex'} gap={1} flexWrap={'wrap'}>
                    {companies && userData && currentUser.user_role === 'super_admin' ?
                      <FormControl sx={{ width: '100%' }} size="small">
                      <FormHelperText id="companies-select">{l('Company')}</FormHelperText>
                        <Select
                          labelId="companies-select-label"
                          id="companies-select"
                          name="company_id"
                          value={parseInt(companies?.find((c) => c.id === userData.company_id)?.id) || parseInt(userData.company_id)}
                          onChange={handleInputChange}
                        >
                          {companies ? companies?.map((company) => (
                            <MenuItem key={company.id} value={parseInt(company.id)}>{company.name}</MenuItem>)) : ""
                          }
                        </Select>
                      </FormControl>
                      :
                      companies ? 
                      <FormControl sx={{ width: '100%' }} size="small">
                      <FormHelperText id="company_id">{l('Company')}</FormHelperText>
                      <OutlinedInput 
                        id="company_id"
                        fullWidth
                        readOnly
                        name="company_id"
                        defaultValue={companies.find((c) => c.id === userData.company_id)?.name || ''}
                        aria-describedby="name"
                        size="small"
                        onChange={handleInputChange}/></FormControl> : <Skeleton variant="rounded" width={'100%'} height={40}/>
                      }
                  </Box>
                  <Box>
                    <FormControlLabel control={
                      <Checkbox id="is_active"
                        checked={userData?.is_active}
                        name="is_active"
                        onChange={handleInputChange}
                        size="small" />
                    } label={l("User is active")} />
                    <FormControlLabel control={
                      <Checkbox id="two_factor_auth"
                        checked={userData?.two_factor_auth}
                        name="two_factor_auth"
                        onChange={handleInputChange}
                        size="small" />
                    } label={l("Two-factor authentication")} />
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    {
                      currentUser?.isAllowEditUsers ?
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          size='small'
                        >
                          {l("Save changes")}
                        </Button>
                        :
                        ""
                    }
                    {
                      currentUser?.isAllowDeleteUsers ?
                        <Button
                          color="error"
                          variant="outlined"
                          size='small'
                          onClick={deleteUser}
                        >
                          {l('Delete user')}
                        </Button>
                        :
                        ""
                    }
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Container>

          :
          ""
      }
    
    </>
  )
}