import en from "./en.js";
import ru from "./ru.js";
import ua from "./ua.js";

const langIndex = {
    en,
    ru,
    ua,
};

export default langIndex;
