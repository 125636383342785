import CircleIcon from '@mui/icons-material/Circle';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Typography from '@mui/material/Typography';
import * as React from 'react';
import useI18n from '../../../hooks/useI18n';

import { useNavigate, useParams } from 'react-router-dom';

import deviceService from '../../../api/device';
import probeService from '../../../api/probe';
import DeviceSchema from '../../custom/Graphics/DeviceSchema';
import Link from '@mui/material/Link';

function DeviceInfo() {
  const { l, language } = useI18n();
  const { id } = useParams();
  const [device, setDevice] = React.useState();
  const navigate = useNavigate();

  React.useEffect(() => {
    const getData = async () => {
      try {
        const result = await deviceService.getFindDeviceById(id, language);
        const probes = await probeService.getFastProbeListByDeviceId({ id: id, lang: language });

        result.probelast = {};
        for (const probe of probes) {
          if (probe.calc_marker) {
            result.probelast[probe.calc_marker] = probe.value_last;
          }
        }
        //getImage(result.id);
        setDevice(result);
      } catch (error) {
        console.error('Error get DEVICE by id :>> ', error);
      }

    };
    getData();

    const interval = setInterval(getData, 10000);
    return () => clearInterval(interval);
  }, []);


  function handleBackButtonClick() {
    navigate('/devices');
  }

  return (<>
    {device?.id ? (
      <Box margin={'10px 0'} sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
          <IconButton onClick={handleBackButtonClick}>
            <ArrowBackIcon />
          </IconButton>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircleIcon color={device.status_flag ? device.status_flag : 'error'} />
              <Typography component="h2" variant="h5">
                {device.comment}
              </Typography>
              <Box padding={'0px 20px'}>
                {device.connected ?
                  <WifiIcon color='success' /> :
                  <WifiOffIcon color='disabled' />
                }
              </Box>
            </Box>
            <Box>
              <Typography>
                {device.ip}:{device.port}
                <Link marginLeft={1} href={`https://${device?.scada_url}.wtp-online.com.ua`} target='_blank' >WEB SCADA</Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <DeviceSchema device={device} />
        {/* <AddProbe /> */}
      </Box>

    ) : ''}
  </>);
}

export default DeviceInfo;