import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/hocs/PrivateRoute";
import RequireAuth from './components/hocs/RequireAuth';
import Layout from "./components/layout/Layout/Layout";
import AccessTokens from "./components/pages/AccessTokens/AccessTokens";
import Alarms from "./components/pages/Alarms/Alarms";
import Companies from './components/pages/Companies/Companies';
import Company from './components/pages/Companies/Company';
import Device from "./components/pages/Devices/Device";
import Devices from "./components/pages/Devices/Devices";
import Forbidden from "./components/pages/Errors/Forbidden";
import Graphics from "./components/pages/Graphics/Graphics";
import ForgotPassword from "./components/pages/Login/ForgotPassword";
import Login from "./components/pages/Login/Login";
import PasswordChange from "./components/pages/Login/PasswordChange";
import VerificationPage from "./components/pages/Login/VerificationPage";
import NotFound from "./components/pages/NotFound/NotFound";
import Probe from "./components/pages/Probes/Probe";
import Role from "./components/pages/Roles/Role";
import Roles from "./components/pages/Roles/Roles";
import SMSSetting from "./components/pages/Settings/SMSSetting";
import UserSetting from "./components/pages/Settings/UserSetting";
import User from "./components/pages/Users/User";
import Users from './components/pages/Users/Users';
import AlarmsType from "./constants/alarms";
import { ERRORS } from "./constants/errors";
import useLogin from "./hooks/useLogin";

function App() {

    const { expiredTokenLogout } = useLogin();

    useEffect(() => {
		document.title = process.env.REACT_APP_NAME || "React App";
        expiredTokenLogout();
    }, []);

  return (
    <>
        <Routes>
			<Route path="/login" element={ <Login /> } />
			<Route path="/forgotPassword" element={ <ForgotPassword /> } />
			<Route path="/varification" element={ <VerificationPage /> } />
			<Route path="/"
				element={
					<RequireAuth>
						<Layout />
					</RequireAuth>
				}
			>
				<Route path="/graphics" element={ <Graphics /> } />
				<Route path="/users" element={ <Users /> } />
				<Route path="/user/:id" element={ <User /> } />
				<Route path="/companies" element={ <PrivateRoute role={'super_admin'} element={<Companies />}/> } />
				<Route path="/company/:id" element={ <PrivateRoute role={'super_admin'} element={<Company />}/> } />
				<Route path="/devices" element={ <Devices /> } />
				<Route path="/device/:id" element={ <Device /> } />
				<Route path="/roles" element={ <PrivateRoute role={'super_admin'} element={<Roles />}/> } />
				<Route path="/device/:deviceId/probe/:probeId" element={ <Probe /> } />
				<Route path="/role/:id" element={ <PrivateRoute role={'super_admin'} element={<Role />}/> } />
				<Route path="/smsSetting" element={ <PrivateRoute role={'super_admin'} element={<SMSSetting />}/> } />
				<Route path="/accessTokens" element={ <AccessTokens /> } />
				<Route path="*" element={ <NotFound /> } />
				<Route path="/passwordChange" element={ <PasswordChange /> } />
				<Route path="/alarms" element={ <Alarms type={AlarmsType.BY_COMPANY} /> } />
				<Route path="/UserSetting" element={ <UserSetting /> } />
				<Route path="/forbidden" element={ <Forbidden error={ ERRORS.FORBIDDEN } /> } />
			</Route>
        </Routes>
    </>
  );
}

export default App;
