import * as React from 'react';
import useI18n from '../../../hooks/useI18n';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReportIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// available types of modal is 'confirm', 'info', 'danger', 'warning', 'success'

export default function BasicModal({isOpened = false, type, title, text, handle}) {
  const [open, setOpen] = React.useState(isOpened);
  const handleClose = () => setOpen(false);
  const { l } = useI18n();
  let colour = ""; 
  let icon = "";

  React.useEffect(() => {
    setOpen(isOpened);
  }, [isOpened])

  function handleOk () {
    if (handle) handle();
    handleClose();
  }

  switch (type) {
    case "confirm": {
      colour = "info.main";
      icon = <InfoIcon sx={{ position: "relative", top: 6}}></ InfoIcon>
      break;
    }
    case "warning": {
      colour = "warning.main";
      icon = <WarningIcon sx={{ position: "relative", top: 6}}></ WarningIcon>
      break;
    }
    case "info": {
      colour = "info.main";
      icon = <InfoIcon sx={{ position: "relative", top: 6}}></ InfoIcon>
      break;
    }
    case "danger": {
      colour = "#c41c1c";
      icon = <ReportIcon sx={{ position: "relative", top: 6}}></ ReportIcon>
      break;
    }
    case "success": {
      colour = "success.main";
      icon = <CheckCircleIcon sx={{ position: "relative", top: 6}}></ CheckCircleIcon>
      break;
    }
    default: {
      colour = "info.main";
      icon = <InfoIcon sx={{ position: "relative", top: 6}}></ InfoIcon>
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Typography id="modal-modal-title" variant="h6" color={colour} component="h2" sx={{fontSize: 24}}>
            {icon}{title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, fontSize: 16 }}>
            {text}
          </Typography>
            {type !== 'confirm' ? 
            <Button size="small" variant="contained" onClick={handleOk}>{l('Ok')}</Button> : 
            <Box>
              <Button onClick={handleOk} sx={{mr: 3}} size="small" variant="outlined">{l('Ok')}</Button>
              <Button onClick={handleClose} size="small" variant="outlined">{l('Cancel')}</Button>
            </Box>}
        </Box>
      </Modal>
    </>
  );
}