import { styled } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import DrawerMenu from '../DrawerMenu/DrawerMenu';


const Layout = () => {

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    return (
        <div className="">
            <div className='Content'>
                <DrawerMenu  />
                <div  className='Outlet'>
                    <DrawerHeader/>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Layout;
