import { atom } from "recoil";

const currentCompanyState = atom({
    key: "currentCompanyState",
    default: {
        id: "",
        created_at: "",
        image_url:"",
    },
});

export { currentCompanyState };