import { allowedLocales } from '../config';

const languageArray = allowedLocales.reduce((acc, locale, index) => {
    acc[index + 1] = locale;
    return acc;
  }, {});

const swappedLanguageArray = {};
for (const key in languageArray) {
    swappedLanguageArray[languageArray[key]] = parseInt(key);
}

export {swappedLanguageArray, languageArray};
