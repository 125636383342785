import { Navigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const PrivateRoute = ({ element, role}) => {
   
    const jwt = sessionStorage.getItem("jwt");
    const decoded = jwtDecode(jwt);

    const location = useLocation();

    if (decoded.user_role === role) {
        return element;
    }

    return <Navigate to="/forbidden" state={{ from: location }} />;;
};

export default PrivateRoute;
