import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import * as React from 'react';

import MenuToolbar from './MenuToolbar';
import SlideBarMenu from './SlideBarMenu';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    width: `100%`,
  }),
}));

export default function DrawerMenu() {
  const [open, setOpen] = React.useState(false);
  const [settingsOpen, setSettingsOpen] = React.useState(false);

  const handleDrawer = () => setOpen(!open);

  return (
    <>
      <AppBar position="fixed" open={open}>
        <MenuToolbar handleDrawer={handleDrawer} open={open}/>
      </AppBar>

      <SlideBarMenu open={open} setOpen={setOpen} settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen}/>
    </>
  );
}
