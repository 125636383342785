export default function LogoRegular({ color, width, height }) {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <svg width={width} height={height} viewBox="0 0 391 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M95.4 15V24H83.4V57H74.4V24H62.4V15H95.4ZM107.993 57V15H123.833C126.133 15 128.213 15.59 130.073 16.77C131.953 17.93 133.443 19.49 134.543 21.45C135.643 23.39 136.193 25.52 136.193 27.84C136.193 30.2 135.623 32.35 134.483 34.29C133.363 36.23 131.863 37.78 129.983 38.94C128.103 40.1 126.053 40.68 123.833 40.68H116.993V57H107.993ZM116.993 31.68H122.813C123.613 31.68 124.343 31.48 125.003 31.08C125.663 30.66 126.193 30.11 126.593 29.43C126.993 28.75 127.193 28 127.193 27.18C127.193 26.36 126.993 25.61 126.593 24.93C126.193 24.25 125.663 23.71 125.003 23.31C124.343 22.89 123.613 22.68 122.813 22.68H116.993V31.68Z" fill={color} />
                <path d="M13.74 57L3 15H12.3L18.78 40.44L25.26 15H33.54L40.02 40.44L46.5 15H55.8L45.12 57H34.98L29.4 35.22L23.88 57H13.74Z" fill={color} />
                <path d="M166.1 84.78C163.26 84.78 160.59 84.22 158.09 83.1C155.59 81.96 153.39 80.39 151.49 78.39C149.59 76.39 148.1 74.08 147.02 71.46C145.94 68.82 145.4 66 145.4 63C145.4 60 145.94 57.19 147.02 54.57C148.1 51.93 149.59 49.61 151.49 47.61C153.39 45.61 155.59 44.05 158.09 42.93C160.59 41.79 163.26 41.22 166.1 41.22C168.96 41.22 171.64 41.79 174.14 42.93C176.64 44.05 178.84 45.61 180.74 47.61C182.64 49.61 184.12 51.93 185.18 54.57C186.26 57.19 186.8 60 186.8 63C186.8 66 186.26 68.82 185.18 71.46C184.12 74.08 182.64 76.39 180.74 78.39C178.84 80.39 176.64 81.96 174.14 83.1C171.64 84.22 168.96 84.78 166.1 84.78ZM166.1 75.78C167.7 75.78 169.2 75.45 170.6 74.79C172.02 74.11 173.27 73.19 174.35 72.03C175.43 70.85 176.27 69.49 176.87 67.95C177.49 66.41 177.8 64.76 177.8 63C177.8 61.24 177.49 59.59 176.87 58.05C176.27 56.51 175.43 55.16 174.35 54C173.27 52.82 172.02 51.9 170.6 51.24C169.2 50.56 167.7 50.22 166.1 50.22C164.5 50.22 162.99 50.56 161.57 51.24C160.17 51.9 158.93 52.82 157.85 54C156.77 55.16 155.92 56.51 155.3 58.05C154.7 59.59 154.4 61.24 154.4 63C154.4 64.76 154.7 66.41 155.3 67.95C155.92 69.49 156.77 70.85 157.85 72.03C158.93 73.19 160.17 74.11 161.57 74.79C162.99 75.45 164.5 75.78 166.1 75.78ZM199.093 84V42H208.813L224.173 67.74V42H233.173V84H223.453L208.093 58.26V84H199.093ZM247.602 84V42H256.602V75H273.102V84H247.602ZM287.811 84V42H296.811V84H287.811ZM312.099 84V42H321.819L337.179 67.74V42H346.179V84H336.459L321.099 58.26V84H312.099ZM360.608 84V42H387.368V51H369.608V57H383.288V66H369.608V75H387.368V84H360.608Z" fill={color} />
                <rect x="5" y="74" width="134" height="10" fill={color} />
                <rect x="153" y="13" width="235" height="10" fill={color} />
            </svg>
        </div>
    );
}