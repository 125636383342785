import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import companyService from '../../../api/company';
import useI18n from '../../../hooks/useI18n';
import { languageArray } from '../../../i18n/selectLang';

export default function AddCompany ({ onSubmitSuccess }) {
    const { l, language } = useI18n();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [company, setCompany] = React.useState({id: null});
    const [locale, setLocale] = React.useState('');
    const [isValidName, setIsValidName] = React.useState(true);

    const handleSubmit = async (event) => {
      event.preventDefault();
      setIsValidName(company.company_name);
      try {
        await companyService.createCompanyAsync(company, language);
        onSubmitSuccess();
        setOpen(false);
      } catch (error) {
          console.error('Error create company:', error);
      }
    }

    const handleInputBlur = (event) => {
      const { name, value } = event.target;
      
      if (name === "timezone") { 
        setCompany({
          ...company,
          [name]: Number(value)
        })
      } else if (name === "locale") {
        setLocale(value);
        setCompany({
          ...company,
          [name]: value
        })
      } else {
        setCompany({
          ...company,
          [name]: value
        })
      }
    }

    function handleCancel () {
      setOpen(false); 
    }

    return (
        <>
          <Button size="small" variant="contained" onClick={handleOpen}>{l('Add company')}</Button>
          <Modal
            open={open}
            onClose={handleClose}
          >
            <Box 
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              className="modal">
              <Typography id="modal-modal-title" variant="h6" color='#1976d2' component="h2" sx={{fontSize: 24}}>
                {l('Add company')}
              </Typography>
              <Box display="flex"
                   flexDirection='column'
                   alignItems="center"
                   justifyContent="space-between"
                   mb={2}>
                <TextField name="company_name"
                           required
                           id="company_name"
                           label={l("Company Name")}
                           onBlur={handleInputBlur}
                           variant="outlined"
                           error={!isValidName}
                           helperText={(!isValidName) && l('Field is required')}
                           size="small"/>
                <TextField name="company_description"
                           id="company_description"
                           label={l("Description")}
                           onBlur={handleInputBlur}
                           variant="outlined"
                           size="small"/>
                <TextField name="timezone"
                           id="timezone"
                           type="number"
                           label={l("Timezone")}
                           onBlur={handleInputBlur}
                           variant="outlined"
                           size="small"/>
                <FormControl sx={{ m: 1, width: '100%' }} size="small">
                  <InputLabel>{l('Locale')}</InputLabel>
                  <Select
                        labelId="locale-select-label"
                        id="locale-select"
                        name="locale"
                        label={l('Locale')}
                        onChange={handleInputBlur}
                        value={locale}
                        >
                        {Object.keys(languageArray).map(k =>
                            <MenuItem key={k} value={languageArray[k]}>{ languageArray[k].toUpperCase()}</MenuItem>
                        )}
                  </Select>
                </FormControl>
              </Box>
              <Box width={'100%'} gap={1} display={'flex'}>
                <Button fullWidth type="submit" size="small" variant="contained" color='success'>{l('Save')}</Button>
                <Button fullWidth onClick={handleCancel} size="small" variant="contained" color='error'>{l('Cancel')}</Button>
              </Box>
            </Box>
          </Modal>
        </>
      );
}